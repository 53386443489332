import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, formatCurrency, formatNumber } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import { downloadCSVDoc, uploadCSVDoc } from '../../../services/apis/shareManagement/InventoryAPI';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import ToastPage from '../../includes/ToastPage';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridMenu } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import ConfirmModalPage from '../../includes/ConfirmModalPage';
import { useSellOrderContext } from '../../../services/contexts/SellOrdersContext';
import SellOrderModalPage from './SellOrderModalPage';
import ErrorModalPage from '../../includes/ErrorModalPage';

export default function SellOrderPage() {

    const { setKycRequesterUserDetail } = useKYCContext()
    const { setCurrModule, allowedFunc, showDelete, setShowDelete, showConfirm, setShowConfirm, getAllRemarkComments, setRemarkComments } = useLogin()
    const { getSellOrder, approvePrice, setApprovePrice, approveQuantity, setApproveQuantity, approveInstrument, setApproveInstrument, editModal, setEditModal, orderId, setOrderId, rejectModal, setRejectModal, setUpdateRemark, getDematList, activeStatusSell, setActiveStatusSell, setAckModal, tableData, setTableData, downloadDeliveryNoteByOrderId, downloadHDFCSheetByOrderId, commentModal, setCommentModal, isDealerOrder, setIsDealerOrder } = useSellOrderContext()
    const { showError, setShowError } = useKYCContext()

    var [count, setCount] = useState(0)
    const [deleteId, setDeleteId] = useState({})
    const [confirmId, setConfirmId] = useState({})
    const [error, setError] = useState("")

    const [selectionModel, setSelectionModel] = useState([]);

    const history = useHistory()

    useEffect(() => {
        (async () => {
            setRemarkComments([])
            setSelectionModel([])
            if (count != 0) {
                await getOrderList()
            }
        })()
    }, [activeStatusSell])

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['sell_module:load_all_sell_orders'])) {
            setCurrModule(configs.modules.smSellOrder)
        } else {
            return history.goBack()
        }
    }, [])

    // function getOrders
    async function getOrderList() {
        try {
            let response = await getSellOrder(configs.sellOrderStatusTypes[activeStatusSell].api)
            if (response.success && response.success === "success") {
                setTableData(response.data)
            } else {

            }
        } catch (error) {

        }
    }


    // columns for various data tables
    const tableColumns = {}
    tableColumns[configs.sellOrderStatusTypes['All Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
    ]
    tableColumns[configs.sellOrderStatusTypes['Completed Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "depository_service", headerName: "Depository Service", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.demat_details.depository_service
            }
        },
        {
            field: "dp_name", headerName: "DP Name", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_name
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.client_id
            }
        },
        {
            field: "download", headerName: "Delivery Note", minWidth: 100, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                return <a href="#" className="custom-link text-primary" onClick={(e) => {
                    e.preventDefault()
                    downloadDeliveryNoteByOrderId(cellValues.row.id)
                }}>Download</a>
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Rejected Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "delete", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:delete_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setDeleteId({
                            "category": configs.modules.smSellOrder,
                            "type": "Order",
                            "name": cellValues.row.id,
                            "id": cellValues.row.id
                        })
                        setShowDelete(true)
                    }}>Delete</a>
                } else {
                    return "Delete"
                }
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Deleted Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "restore", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:restore_buy_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setShowConfirm(true)
                        setConfirmId({
                            "category": configs.modules.smSellOrder,
                            "type": "Order",
                            "name": cellValues.row.id,
                            "id": cellValues.row.id
                        })
                    }}>Restore</a>
                } else {
                    return "Restore"
                }
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Cancelled Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
    ]
    tableColumns[configs.sellOrderStatusTypes['Customer Generated'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "edit", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:update_sell_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setApproveQuantity(cellValues.row.quantity)
                        setApprovePrice(cellValues.row.price_per_share)
                        setApproveInstrument(cellValues.row.company_name)
                        setEditModal(true)
                    }}>Edit</a>
                } else {
                    return "Edit"
                }
            }
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:reject_sell_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['sell_module:approve_or_reject_generated_order'])
                        setOrderId(cellValues.row.id)
                        setUpdateRemark(configs.functionList['sell_module:reject_sell_order'])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "approve", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:approve_order_and_set_status_to_share_transfer_pending'])) {
                    return <a href="#" className="custom-link text-success" onClick={(e) => {
                        e.preventDefault()
                        getDematList()
                        setOrderId(cellValues.row.id)
                        setUpdateRemark(configs.functionList['sell_module:approve_order_and_set_status_to_share_transfer_pending'])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Approve</a>
                } else {
                    return "Approve"
                }
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Share Transfer Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "depository_service", headerName: "Depository Service", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.demat_details.depository_service
            }
        },
        {
            field: "dp_name", headerName: "DP Name", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_name
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.client_id
            }
        },
        {
            field: "edit", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:update_sell_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setApproveQuantity(cellValues.row.quantity)
                        setApprovePrice(cellValues.row.price_per_share)
                        setApproveInstrument(cellValues.row.company_name)
                        setEditModal(true)
                    }}>Edit</a>
                } else {
                    return "Edit"
                }
            }
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:reject_sell_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['sell_module:share_transfer_pending'])
                        setOrderId(cellValues.row.id)
                        setUpdateRemark(configs.functionList['sell_module:reject_sell_order'])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "confirm", headerName: " ", minWidth: 150, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:reject_sell_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setRemarkComments([])
                        setOrderId(cellValues.row.id)
                        setUpdateRemark(configs.functionList["sell_module:transfer_share_on_users_behalf"])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Confirm Share Transfer</a>
                } else {
                    return "Confirm Share Transfer"
                }
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Share Transfer Acknowledgement Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "depository_service", headerName: "Depository Service", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.demat_details.depository_service
            }
        },
        {
            field: "dp_name", headerName: "DP Name", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_name
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.client_id
            }
        },
        {
            field: "acknowledge", headerName: " ", minWidth: 100, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:acknowledge_share_transfer'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setApproveInstrument(cellValues.row.company_name)
                        setAckModal(true)
                    }}>Acknowledge</a>
                } else {
                    return "Acknowledge"
                }
            }
        }
    ]
    tableColumns[configs.sellOrderStatusTypes['Payment Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.utm ? params.row.utm : ""
            }, valueGetter: (params) => params.row && params.row.utm ? params.row.utm : ""
        },
        {
            field: "is_dealer_order", headerName: "Dealer Order", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.is_dealer_order ? "Yes" : "No"
            }, valueGetter: (params) => params.row && params.row.is_dealer_order ? "Yes" : "No"
        },
        {
            field: "dealer_transaction_utr_confirmation", headerName: "Dealer UTR", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
            }, valueGetter: (params) => params.row && params.row.dealer_transaction_utr_confirmation ? params.row.dealer_transaction_utr_confirmation : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
            }, valueGetter: (params) => params.row && params.row.utm_recorded_from ? params.row.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.email ? params.row.relationship_manager && params.row.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.relationship_manager && params.row.relationship_manager.name ? params.row.relationship_manager && params.row.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_per_share))
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "tds", headerName: "TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.tds))
            }, valueGetter: (value) => value.row.tds
        },
        {
            field: "payable_amount", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.payable_amount))
            }, valueGetter: (value) => value.row.payable_amount
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "customer_remark", headerName: "Remark", minWidth: 250, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        { field: "statusMessage", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.comment && params.row.comment.text ? params.row.comment.text : ""} <i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.comment && params.row.comment.text ? params.row.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.commenter && params.row.comment.commenter.name ? params.row.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
            }, valueGetter: (params) => params.row.comment && params.row.comment.timestamp ? params.row.comment.timestamp : ""
        },
        {
            field: "depository_service", headerName: "Depository Service", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.demat_details.depository_service
            }
        },
        {
            field: "dp_name", headerName: "DP Name", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_name
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", hide: true, renderCell: (params) => {
                return params.row.demat_details.client_id
            }
        },
        {
            field: "bank_name", headerName: "Bank Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.user.bank.bank_name
            }
        },
        {
            field: "account_number", headerName: "Account Number", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.user.bank.account_number
            }
        },
        {
            field: "ifsc_code", headerName: "IFSC Code", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12 text-capitalize", renderCell: (params) => {
                return params.row.user.bank.ifsc_code
            }
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:reject_sell_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['sell_module:share_transfer_pending'])
                        setOrderId(cellValues.row.id)
                        setUpdateRemark(configs.functionList['sell_module:reject_sell_order'])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "complete", headerName: " ", minWidth: 50, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['sell_module:mark_order_as_completed'])) {
                    return <a href="#" className="custom-link text-success" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['sell_module:payment_completed'])
                        setOrderId(cellValues.row.id)
                        setIsDealerOrder(cellValues.row.is_dealer_order)
                        setUpdateRemark(configs.functionList['sell_module:mark_order_as_completed'])
                        setApproveInstrument(cellValues.row.company_name)
                        setRejectModal(true)
                    }}>Complete</a>
                } else {
                    return "Complete"
                }
            }
        }
    ]


    // download hdfc sheet
    async function exportHDFC() {
        try {
            let ele = document.getElementById('buyOrderContainer').click();
            let response = await downloadHDFCSheetByOrderId(selectionModel)
            if (response && response === "success") {
                setSelectionModel([])
            } else {
                setError(response)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // add hdfc export option in export toolbar
    function addHDFCExportButton() {
        try {
            setTimeout(() => {
                // get root element to add element
                let ele = document.getElementsByClassName("MuiList-root MuiDataGrid-gridMenuList MuiList-padding")
                if ((ele.length > 0) && (selectionModel.length > 0) && (activeStatusSell === configs.sellOrderStatusTypes["Payment Pending"].name)) {
                    //create new element to add
                    var li = document.createElement('li')
                    li.className = "MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button";
                    let span = document.createElement('span');
                    span.className = "MuiTouchRipple-root"
                    li.appendChild(document.createTextNode("HDFC Sheet"))
                    li.appendChild(span)
                    li.tabIndex = 0
                    li.setAttribute("role", "menu-item")
                    li.ariaDisabled = "false"
                    li.addEventListener("click", exportHDFC);

                    //add new elemet to parent
                    ele[0].appendChild(li)
                }
            }, 200)
        } catch (error) {
            console.error(error)
        }
    }

    // react data table export option
    function CustomToolbar() {
        try {
            let ele = document.querySelectorAll("[aria-label^='Export']");
            if (ele.length > 0) {
                ele[0].addEventListener("click", addHDFCExportButton)
            }
        } catch (error) {
            console.error(error)
        }
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='buyOrderContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row mx-md-4 mx-2 my-2 p-0">
                            <div className="col-md-6 m-0 p-0">
                                <p className="h5 text-custom-grey">{activeStatusSell}</p>
                            </div>
                            <div className="col-md-6 text-md-right text-left m-0 p-0">
                                <span className="text-primary mr-2">Status</span>
                                <select name="" id="" className="w-50 py-1 px-2 border rounded" value={activeStatusSell ? activeStatusSell : ""} onChange={(e) => {
                                    setTableData("")
                                    setActiveStatusSell(e.target.value)
                                }}>
                                    {Object.keys(configs.sellOrderStatusTypes).map((element, key) => {
                                        if (allowedFunc.includes(configs.sellOrderStatusTypes[element].functionName)) {
                                            if (count === 0) {
                                                setActiveStatusSell(configs.sellOrderStatusTypes[element].name)
                                                setCount(count += 1)
                                            }
                                            return (
                                                <option key={`shareStatusKey` + key} value={configs.sellOrderStatusTypes[element].name}>{configs.sellOrderStatusTypes[element].name}</option>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns[configs.sellOrderStatusTypes[activeStatusSell].name]}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                checkboxSelection={activeStatusSell === configs.sellOrderStatusTypes["Payment Pending"].name ? true : false}
                                onSelectionModelChange={(newSelectionModel) => {
                                    if (activeStatusSell === configs.sellOrderStatusTypes["Payment Pending"].name) {
                                        setSelectionModel(newSelectionModel);
                                        let ele = document.getElementById('buyOrderContainer').click();
                                    }
                                }}
                                selectionModel={activeStatusSell === configs.sellOrderStatusTypes["Payment Pending"].name ? selectionModel : []}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <SellOrderModalPage />
                <ToastPage />
                {showDelete ? <DeleteModalPage {...deleteId} /> : showConfirm ? <ConfirmModalPage {...confirmId} /> : null}
                <ErrorModalPage {...{ error: error }} />
            </div>
        </>
    )
}