import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs, convertToBlob } from '../../assets/Config';
import { customerCompleteKYC, customerProLeads, customerProPayment, customerProUserReport, customerXclusiveLeads, customerXclusivePayment, getAllUserAction, getAllUserDisclaimer, getCustomerDocuments, getCustomerStatistics, getUTMList, kycRequesters, kycVerified, updateBeneficiaryStatus, updateTurnOverStatus } from '../apis/KYCApi';
import { useLogin } from "./AdminLoginContext";

// create global login context
export const KYCContext = createContext();

export function KYCProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();
    const [error, setError] = useState("");
    const [kycRequestersList, setKycRequestersList] = useState("");
    const [kycVerifiedList, setKycVerifiedList] = useState("");
    const [kycRequesterUserDetail, setKycRequesterUserDetail] = useState("");
    const [kycRequesterUserComplete, setKycRequesterUserComplete] = useState("");
    const [panDoc, setpanDoc] = useState("null");
    const [addressDoc, setaddressDoc] = useState("null");
    const [dematDoc, setdematDoc] = useState("null");
    const [otherDoc, setOtherDoc] = useState("null");
    const [bankDoc, setbankDoc] = useState("null");
    const [showError, setShowError] = useState(false);
    const [activeStatus, setActiveStatus] = useState("")
    const [proLeads, setProLeads] = useState("");
    const [actions, setActions] = useState("");
    const [disclaimer, setDisclaimer] = useState("");
    const [xclusiveLeads, setXclusiveLeads] = useState("");
    const [proPayment, setProPayment] = useState("");
    const [proUserReport, setProUserReport] = useState("");
    const [utmList, setUtmList] = useState([])


    // get pro leads
    async function getProLeads() {
        try {
            let response = await customerProLeads(token);
            if (response && response.status === 200) {
                setProLeads(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get pro payment detail
    async function getProPayment() {
        try {
            let response = await customerProPayment(token);
            if (response && response.status === 200) {
                setProPayment(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get xclusive leads
    async function getXclusiveLeads() {
        try {
            let response = await customerXclusiveLeads(token);
            if (response && response.status === 200) {
                setXclusiveLeads(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get xclusive payment detail
    async function getXclusivePayment() {
        try {
            let response = await customerXclusivePayment(token);
            if (response && response.status === 200) {
                setProPayment(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get pro user report
    async function getProUserReport() {
        try {
            let response = await customerProUserReport(token);
            if (response && response.status === 200) {
                setProUserReport(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get utm list
    async function getAllUTMList() {
        try {
            let response = await getUTMList(token);
            let result = [];
            if (response && response.status === 200) {
                response.data.forEach((element, key) => {
                    element.utm.forEach((utme, key) => {
                        result.push({
                            distributor: element.name,
                            utm: utme,
                            email: element.email
                        })
                    })
                })

                setUtmList(result)
            }
            else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            }
        } catch (error) {

        }
    }

    // get kyc all requesters
    async function getKYCRequesters() {
        try {
            let response = await kycRequesters(token);
            if (response && response.status === 200) {
                setKycRequestersList(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get kyc verified users
    async function getKYCVerifiedCustomer() {
        try {
            let response = await kycVerified(token);
            if (response && response.status === 200) {
                setKycVerifiedList(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {

        }
    }

    // get complete kyc detail of customer
    async function getKYCCustomerDetail(type) {
        try {
            let response = await customerCompleteKYC(token, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                setKycRequesterUserComplete(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            getPanProof(type)
            getAddressProof(type)
            getBankProof(type)
            getDematProof(type)
            getOtherProof(type)

            return response.data
        } catch (error) {

        }

    }

    // get customer stats
    async function getCustomerStats(type) {
        try {
            let response = await getCustomerStatistics(token, type);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }

            getPanProof()
            getAddressProof()
            getBankProof()
            getDematProof()

            return response.data
        } catch (error) {

        }

    }

    // get customer uploaded documents
    async function getPanProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.pan_card, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf") || response.data.includes("application/octet-stream")) {
                    setpanDoc(response.data)
                    convertToBlob(response.data, `${type}panFrame`)
                } else {
                    setpanDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getAddressProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.address_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf") || response.data.includes("application/octet-stream")) {
                    setaddressDoc(response.data)
                    convertToBlob(response.data, `${type}addressFrame`)
                } else {
                    setaddressDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getBankProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.bank_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf") || response.data.includes("application/octet-stream")) {
                    setbankDoc(response.data)
                    convertToBlob(response.data, `${type}bankFrame`)
                } else {
                    setbankDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getDematProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.demat_proof, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf") || response.data.includes("application/octet-stream")) {
                    setdematDoc(response.data)
                    convertToBlob(response.data, `${type}dematFrame`)
                } else {
                    setdematDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }
    async function getOtherProof(type) {
        try {
            type = type === undefined ? "" : type
            let response = await getCustomerDocuments(token, configs.docTypes.other_document, kycRequesterUserDetail.id);
            if (response && response.status === 200) {
                if (response.data.includes("application/pdf") || response.data.includes("application/octet-stream")) {
                    setOtherDoc(response.data)
                    convertToBlob(response.data, `${type}otherFrame`)
                } else {
                    setOtherDoc(response.data)
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {

        }
    }


    async function changeBeneficiaryStatus(userId, status) {
        try {
            let response = await updateBeneficiaryStatus(token, userId, status);
            if (response && response.status === 200) {
                return true;
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function changeTurnOverStatus(userId, status) {
        try {
            let response = await updateTurnOverStatus(token, userId, status);
            if (response && response.status === 200) {
                return true;
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAllUserActions() {
        try {
            let response = await getAllUserAction(token);
            if (response && response.status === 200) {
                setActions(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {
            console.error(error);
        }
    }

    async function getAllUserDiscalimers() {
        try {
            let response = await getAllUserDisclaimer(token);            
            if (response && response.status === 200) {
                setDisclaimer(response.data)
            }
            else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { setError(response.data.error) }
        } catch (error) {
            console.error(error);
        }
    }

    // export global context
    return (
        <KYCContext.Provider
            value={{
                error,
                getKYCRequesters,
                utmList,
                setUtmList,
                getAllUTMList,
                kycRequestersList,
                kycRequesterUserDetail,
                setKycRequesterUserDetail,
                kycVerifiedList,
                getKYCVerifiedCustomer,
                kycRequesterUserComplete,
                setKycRequesterUserComplete,
                getKYCCustomerDetail,
                panDoc,
                addressDoc,
                bankDoc,
                dematDoc,
                otherDoc,
                setpanDoc,
                setaddressDoc,
                setbankDoc,
                setdematDoc,
                setOtherDoc,
                showError,
                setShowError,
                getCustomerStats,
                activeStatus,
                setActiveStatus,
                getProLeads,
                proLeads,
                getProPayment,
                getXclusivePayment,
                proPayment,
                getProUserReport,
                proUserReport,
                getXclusiveLeads,
                xclusiveLeads,
                changeBeneficiaryStatus,
                changeTurnOverStatus,
                actions,
                setActions,
                getAllUserActions,
                disclaimer,
                setDisclaimer,
                getAllUserDiscalimers
            }}
        >
            {children}
        </KYCContext.Provider>
    );
}

// use kyc context globally
export function useKYCContext() {
    return useContext(KYCContext);
}