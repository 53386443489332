import React, { useEffect, useState } from "react";
import { configs } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import "../../../assets/styles/BuyOrder.css"
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useKYCContext } from "../../../services/contexts/KYCContext";
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext";
import ToastPage from "../../includes/ToastPage";
import { useHistory } from "react-router";
import { useSellOrderContext } from "../../../services/contexts/SellOrdersContext";
import { useDistributorContext } from "../../../services/contexts/DistributorContext";

export default function ManualPunchPage() {

    const { kycRequestersList, getKYCRequesters } = useKYCContext()
    const { getCompanyList, getCompanySlabsByCompanyId, manualOrderPunch } = useBuyOrderContext()
    const { sellManualOrderPunch } = useSellOrderContext()
    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [pan, setPan] = useState("")
    const [companyList, setCompanyList] = useState("")
    const [companySlabs, setCompnaySlabs] = useState("")
    const [orderType, setOrderType] = useState("buy");
    const [dealerOrder, setDealerOrder] = useState(0);

    const [companyId, setCompanyId] = useState("")
    const [customerId, setCustomerId] = useState("")
    const [quantity, setQuantity] = useState("")

    const [distributor, setDistributor] = useState("")
    const [upmark, setUpmark] = useState("")
    const [upmarkPay, setUpmarkPay] = useState("")

    const [price, setPrice] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            if (allowedFunc.includes(configs.functionList["buy_module:buy_order_manual_punching"]) || allowedFunc.includes(configs.functionList["sell_module:sell_order_manual_punching"])) {
                (async () => {
                    setCurrModule(configs.modules.manualPunch)
                    await getKYCRequesters()
                    let response = await getCompanyList()
                    if (response.success) {
                        setCompanyList(response.data)
                    } else {

                    }
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    const handleNameChange = (e, value) => {
        try {
            setEmail(value.email)
            setName(value.name)
            setPan(value.pan_number)
            setMobile(value.mobile ? `+${value.mobile}` : value.mobile)
            setCustomerId(value.id)
            setDistributor(value.distributor);
        } catch (error) {

        }
    }

    const handleCompanyChange = (e, value) => {
        try {
            setCompanyId(value.id)
        } catch (error) {

        }
    }

    // manual order punch
    async function manualPunch() {
        try {
            document.getElementById("manualPunchBtn").classList.toggle("d-none")
            document.getElementById("manualPunchBtnLoader").classList.toggle("d-none")

            const re = /^[0-9]+$/;
            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            if (!customerId) {
                setError("Please select a valid customer.")
            } else if (!companyId) {
                setError("Please select a valid company.")
            } else if (!quantity.match(re)) {
                setError("Please enter a valid quantity with only numbers.")
            } else if (!price.toString().match(red)) {
                setError("Please enter a valid price with only numbers.")
            } else if ((!upmark && upmarkPay && distributor.id) || (upmark && isNaN(upmark))) {
                setError("Please enter a valid Upmark value.")
            } else if ((upmark && !upmarkPay && distributor.id) || (upmarkPay && isNaN(upmarkPay))) {
                setError("Please enter a valid Upmark Final Pay value.")
            } else if ((upmark && upmarkPay && !distributor.id)) {
                setError("Please select a valid distributor.")
            } else {
                let response;
                if (orderType === "buy") {
                    response = await manualOrderPunch(customerId, companyId, price, quantity, distributor.id, upmark, upmarkPay)
                } else if (orderType === "sell") {
                    response = await sellManualOrderPunch(customerId, companyId, price, quantity, dealerOrder, distributor.id, upmark, upmarkPay)
                } else {
                    setError("You are not authorized to manually punch order.")
                }
                if (response.success) {
                    setEmail("")
                    setName("")
                    setPan("")
                    setMobile("")
                    setCustomerId("")
                    setQuantity("")
                    setPrice("")
                    setError("")
                    setShowSuccess(true)
                    setOrderType("buy")
                    setDealerOrder(0)
                    setDistributor("")
                    setUpmark("")
                    setUpmarkPay("")
                } else {
                    setError(response)
                }
            }
            document.getElementById("manualPunchBtn").classList.toggle("d-none")
            document.getElementById("manualPunchBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id="mpOrder" className="smPO-container">
                    <div className="col-12 text-left">
                        <p className="h3 text-custom-greyDark mb-0">Punch Manual Order</p>
                    </div>
                    {kycRequestersList && companyList ?
                        <div className="smPOI-container">
                            <form action="">
                                <div className="form-row py-3">
                                    <div className="col-lg-3 form-group">
                                        <label>Customer E-mail</label>
                                        {kycRequestersList ? <Autocomplete
                                            className="f-12"
                                            id="emailIP"
                                            placeholder="E-mail"
                                            getOptionLabel={option => option.email}
                                            onChange={handleNameChange}
                                            options={kycRequestersList}
                                            renderInput={(params) => <TextField {...params} placeholder="E-mail" className="f-12" />}
                                        /> : null}
                                        {/* <input type="text" className="form-control f-10" placeholder="Enter email" list="userEmail-datalist" /> */}
                                    </div>
                                    <div className="col-lg-3 form-group">
                                        <label>Customer Name</label>
                                        <input type="text" className="form-control f-10 py-2" value={name ? name : ""} placeholder="Name" disabled />
                                    </div>
                                    <div className="col-lg-3 form-group">
                                        <label>Customer Mobile</label>
                                        <input type="text" className="form-control f-10 py-2" value={mobile ? mobile : ""} placeholder="Mobile No" disabled />
                                    </div>
                                    <div className="col-lg-3 form-group">
                                        <label>Customer Pan Number</label>
                                        <input type="text" className="form-control f-10 py-2" value={pan ? pan : ""} placeholder="PAN No" disabled />
                                    </div>
                                </div>
                                <div className="form-row py-3">
                                    <div className="col-lg-6 form-group">
                                        <label>Company Name</label>
                                        {companyList ? <Autocomplete
                                            className="f-12"
                                            id="companyIP"
                                            placeholder="Compnay Name"
                                            getOptionLabel={option => option.name}
                                            onChange={handleCompanyChange}
                                            options={companyList}
                                            renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                        /> : null}
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label>Order Type</label>
                                        <select name="" id="" className="form-control f-10 py-2 dropDown" value={orderType} onChange={(e) => {
                                            setOrderType(e.target.value)
                                        }}>
                                            {allowedFunc.includes(configs.functionList["buy_module:buy_order_manual_punching"]) ? <option value="buy">Buy</option> : null}
                                            {allowedFunc.includes(configs.functionList["sell_module:sell_order_manual_punching"]) ? <option value="sell">Sell</option> : null}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-row py-3">
                                    <div className="col-lg-4 form-group">
                                        <label>Quantity</label>
                                        <input type="text" className="form-control f-10 py-2" placeholder="Qty" value={quantity} onChange={(e) => {
                                            setQuantity(e.target.value)
                                        }} />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Price</label>
                                        <input type="text" className="form-control f-10 py-2" placeholder="Price" value={price} onChange={(e) => {
                                            setPrice(e.target.value)
                                        }} />
                                    </div>
                                    {orderType === "sell" ?
                                        <div className="col-lg-4 form-group d-flex align-items-center">
                                            <input type="checkbox" className="" placeholder="Dealer Order" checked={dealerOrder} onChange={(e) => {
                                                setDealerOrder(e.target.checked)
                                            }} />
                                            <label className="m-0">Dealer Order</label>
                                        </div>
                                        : null}
                                </div>
                                {distributor && distributor.id ?
                                    <div className="form-row py-3">
                                        <div className="col-lg-4 form-group">
                                            <label>Distributor Name</label>
                                            <input type="text" className="form-control f-10 py-2" value={distributor ? distributor.name : ""} placeholder="Distributor" disabled />
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Upmark Value</label>
                                            <input type="number" className="form-control f-10 py-2" placeholder="Upmark" value={upmark} onChange={(e) => {
                                                setUpmark(e.target.value)
                                            }} />
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <label>Upmark final pay Value</label>
                                            <input type="number" className="form-control f-10 py-2" placeholder="Upmark Final Pay" value={upmarkPay} onChange={(e) => {
                                                setUpmarkPay(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                    : null}
                                <div className="form-check p-0">
                                    <p className="text-danger" id="manualOrderPunchError">{error}</p>
                                </div>

                                <div className="col-12 d-flex justify-content-center mt-3">
                                    <button id="manualPunchBtn" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        manualPunch()
                                    }}>Submit</button>
                                    <div id="manualPunchBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </form>
                        </div>
                        : <div className="loader"></div>}

                </div>
                <ToastPage />
            </div >
        </>
    )
}