import React, { useEffect, useState } from 'react';
import '../../../assets/styles/KYC.css';
import {
    DataGrid, GridToolbar, GridToolbarContainer,
    GridToolbarExport,
} from '@material-ui/data-grid';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { configs } from '../../../assets/Config';
import { Link } from 'react-router-dom';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import ToastPage from '../../includes/ToastPage';

export default function KYCReviewRequestsPage() {

    const { error, getKYCRequesters, kycRequestersList, setKycRequesterUserDetail, setKycRequesterUserComplete, setpanDoc,
        setaddressDoc,
        setbankDoc,
        setdematDoc, changeBeneficiaryStatus, changeTurnOverStatus, setShowError
    } = useKYCContext()

    const { allowedFunc, setShowSuccess } = useLogin()

    // declare table rows
    const [columns, setColumns] = useState([
        { field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
        // { field: "email", headerName: "Email", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row)
                    }}>{params.row.email}</Link>
                } else {
                    return params.row.email
                }
            }, valueGetter: (value) => value.row.email
        },
        { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
        { field: "nationality", headerName: "Nationality", minWidth: 100, hide:true, flex: 0.4, cellClassName: "break-text" },
        // { field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text" },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.mobile} target="_blank">{params.row.mobile ? `+${params.row.mobile}` : params.row.mobile}</a>
            }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
        },
        { field: "pan_number", headerName: "Pan Number", minWidth: 100, flex: 0.5, hide: true },
        { field: "address", headerName: "Address", minWidth: 100, flex: 0.5, hide: true },
        { field: "bank_name", headerName: "Bank Name", minWidth: 100, flex: 0.5, hide: true },
        { field: "bank_account_number", headerName: "Account Number", minWidth: 100, flex: 0.5, hide: true },
        { field: "bank_ifsc_code", headerName: "IFSC Code", minWidth: 100, flex: 0.5, hide: true },
        { field: "dp_ip", headerName: "Dp ID", minWidth: 100, flex: 0.5, hide: true },
        { field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.5, hide: true },
        { field: "depository_service", headerName: "Depository Service", minWidth: 100, flex: 0.5, hide: true },
        { field: "remark", headerName: "Remarks", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
        { field: "internal_remark", headerName: "Internal Remark", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
        {
            field: "is_beneficiary_added", headerName: "Beneficiary Added", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return <input type="checkbox" id="ben_add" name="ben_add" checked={params.row.is_beneficiary_added} onChange={async (e) => {
                    e.preventDefault();
                    let response = await changeBeneficiaryStatus(params.row.id, e.target.checked);
                    if (response) {
                        getKYCRequesters();
                        setShowSuccess(true);
                    } else {
                        setShowError(true);
                    }
                }} />
            }, valueGetter: (params) => params.row.is_beneficiary_added ? "Yes" : "No"
        },
        {
            field: "is_turn_over_above_10_cr", headerName: "10 Cr", minWidth: 40, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return <input type="checkbox" id="turnover10cr" name="turnover10cr" checked={params.row.is_turn_over_above_10_cr} onChange={async (e) => {
                    e.preventDefault();
                    let response = await changeTurnOverStatus(params.row.id, e.target.checked);
                    if (response) {
                        getKYCRequesters();
                        setShowSuccess(true);
                    } else {
                        setShowError(true);
                    }
                }} />
            }, valueGetter: (params) => params.row.is_beneficiary_added ? "Yes" : "No"
        },
        { field: "account_created_on", headerName: "Account Creation Time", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
        {
            field: "status", headerName: "Status", minWidth: 100, flex: 0.2, cellClassName: (params) => {
                let clas;
                if ((params.value) === configs.statusValues.rejected) {
                    clas = "text-danger"
                }
                else if ((params.value) === configs.statusValues.verified) {
                    clas = "text-success"
                }
                else if ((params.value) === configs.statusValues.pending) {
                    clas = "text-primary"
                }
                return clas + " text-capitalize break-text"

            }
        },
        {
            field: "review", headerName: " ", minWidth: 70, flex: 0.2, cellClassName: "text-primary break-text", filterable: false, renderCell: (params) => {
                return <Link to={`/kyc/${params.row.id}`} className="custom-link" onClick={(e) => {
                    setKycRequesterUserDetail(params.row);
                }}>Review</Link>
            }
        },
        {
            field: "account_type", headerName: "Account Type", minWidth: 80, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return params.row.account_type === configs.accountType[1] ? "PRO Plus" : params.row.account_type === configs.accountType[2] ? "PRO Lite" : params.row.account_type
            }, valueGetter: (params) => params.row.account_type === configs.accountType[1] ? "PRO Plus" : params.row.account_type === configs.accountType[2] ? "PRO Lite" : params.row.account_type
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 100, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return params.row.distributor.name ? params.row.distributor.name : ""
            }, valueGetter: (params) => params.row.distributor.name ? params.row.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return params.row.distributor.utm ? params.row.distributor.utm : ""
            }, valueGetter: (params) => params.row.distributor.utm ? params.row.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
                return params.row.distributor.utm_recorded_from ? params.row.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.distributor.utm_recorded_from ? params.row.distributor.utm_recorded_from : ""
        }
    ])

    // get list of kyc requesters
    useEffect(() => {
        (async () => {
            try {
                await getKYCRequesters();
                setKycRequesterUserComplete(null)
                setpanDoc("null")
                setaddressDoc("null")
                setbankDoc("null")
                setdematDoc("null")
            } catch (error) {

            }
        })();
    }, [])

    // react data table export option
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // admin kyc requesters basic details ui code
    return (<div className="container-fluid">
        <div className="row position-relative px-2">
            <p className="text-custom-grey h3 mt-2 ml-3">KYC</p>
            <div className="kyc-table">
                {kycRequestersList ? <DataGrid
                    rows={kycRequestersList}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    rowHeight={40}
                    headerHeight={30}
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
                    : <div className="loader"></div>}
            </div>
        </div>
        <ToastPage />
    </div>

    )
}