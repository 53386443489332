import React, { useEffect, useState } from "react";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import ToastPage from "../../includes/ToastPage";
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext";
import { configs } from "../../../assets/Config";
import { useHistory } from 'react-router';
import { useLogin } from "../../../services/contexts/AdminLoginContext";

export default function CCAvenueHistory() {
    const { getCCAvenueHistoryByOrderId } = useBuyOrderContext();
    const { setCurrModule, allowedFunc } = useLogin()
    const [ccBreakup, setCCBreakup] = useState("");
    const [orderId, setOrderId] = useState("");
    const [error, setError] = useState("");

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.smCCAvenueHistory)
                if (allowedFunc.includes(configs.functionList['buy_module:load_all_buy_orders'])) {

                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    async function getCCAvenueHistory(e) {
        try {
            e.preventDefault();
            document.getElementById("ccHistoryBtn").classList.toggle("d-none")
            document.getElementById("ccHistoryBtnLoader").classList.toggle("d-none")
            if (!orderId) {
                setError("Please enter a valid Order Id.");
            } else {
                let response = await getCCAvenueHistoryByOrderId(orderId);
                if (response && response.success) {
                    setCCBreakup(response.data)
                    setError("");
                }
            }
        } catch (error) {
            console.error(error);
            setError(error)
        } finally {
            document.getElementById("ccHistoryBtn").classList.toggle("d-none")
            document.getElementById("ccHistoryBtnLoader").classList.toggle("d-none")

        }
    }


    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }} className="px-2">
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-6 text-left">
                                <p className="h4 text-custom-grey">CCAvenue Transaction History</p>
                            </div>
                        </div>
                        <div>
                            <form onSubmit={getCCAvenueHistory}>
                                <div className="row m-0 p-0">
                                    <div className="col-md-4 m-0 col-12">
                                        <input type="number" className="form-control" placeholder="Enter Order Id" value={orderId} onChange={(e) => { setOrderId(e.target.value) }} />
                                        <div className="form-check row">
                                            <p className="text-danger" id="createRoleError">{error}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 m-0 col-12">
                                        <button id="ccHistoryBtn" className="btn btn-primary f-12" onClick={(e) => {
                                            getCCAvenueHistory(e)
                                        }}>Show History</button>
                                        <div id="ccHistoryBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>                                    </div>
                                </div>

                            </form>
                        </div>
                        {ccBreakup && ccBreakup.length > 0 ?
                            <div className="table-responsive mx-2 mt-2">
                                <table className="table table-bordered">
                                    <tr>
                                        <th>Tracking Id</th>
                                        <th>Bank Ref No</th>
                                        <th>Order Status</th>
                                        <th>Failure Message</th>
                                        <th>Payment Mode</th>
                                        <th>Card Name</th>
                                        <th>Status Code</th>
                                        <th>Status Message</th>
                                        <th>Currency</th>
                                        <th>Amount</th>
                                        <th>Mer Amount</th>
                                        <th>Trans Date</th>
                                        <th>Timestamp</th>
                                    </tr>
                                    {ccBreakup.map((element, key) => {
                                        return (
                                            <tr key={`ccbreakup+${key}`}>
                                                <td>{element.ccavenue.tracking_id}</td>
                                                <td>{element.ccavenue.bank_ref_no}</td>
                                                <td>{element.ccavenue.order_status}</td>
                                                <td>{element.ccavenue.failure_message}</td>
                                                <td>{element.ccavenue.payment_mode}</td>
                                                <td>{element.ccavenue.card_name}</td>
                                                <td>{element.ccavenue.status_code}</td>
                                                <td>{element.ccavenue.status_message}</td>
                                                <td>{element.ccavenue.currency}</td>
                                                <td>{element.ccavenue.amount}</td>
                                                <td>{element.ccavenue.mer_amount}</td>
                                                <td>{element.ccavenue.trans_date}</td>
                                                <td>{element.ccavenue.timestamp}</td>

                                            </tr>
                                        )
                                    })}

                                </table>
                            </div>
                            : null}
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}