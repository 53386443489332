import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../../assets/Config';
import { useLogin } from '../../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import ToastPage from '../../../includes/ToastPage';
import '../../../../assets/styles/RM.css'
import UniqueEntryPage from './UniqueEntryPage';
import CreateEntryPage from './CreateEntryPage';
import { useCRMContext } from '../../../../services/contexts/CRMContext';
import { useKYCContext } from '../../../../services/contexts/KYCContext';
import { useRMContext } from '../../../../services/contexts/RMContext';
import { useCMContext } from '../../../../services/contexts/companyManagement/CMContext';


export default function CRMOrderInsightMainPage() {

    const { getAllRemarkList, showDeletedRemark } = useCRMContext()
    const { setCurrModule, allowedFunc } = useLogin()
    const { getKYCRequesters } = useKYCContext()
    const { getAllAdmin } = useRMContext()
    const { getAllCompaniesList } = useCMContext();

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['crm:get_insights']) || allowedFunc.includes(configs.functionList['crm:add_insight'])) {
                    setCurrModule(configs.modules.OrderInsight)
                    // getAllRemarkList("order-insight")
                    getKYCRequesters()
                    getAllCompaniesList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='rmContainer' className="rm-container">
                    <UniqueEntryPage />
                    <CreateEntryPage />
                    <ToastPage />
                </div>
            </div>
        </>
    )
}