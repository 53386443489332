import axios from 'axios';
import { configs } from '../../../assets/Config';

// get form leads
export function distributorFormLeads(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/formLeads`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get blacklisted assets
export function getDistributorBlackListedAsset(token, distributorId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/blacklistAssetClass/${distributorId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// add blacklisted assets
export function addDistributorBlackListedAsset(token, distributorId, assetList) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "distributor_id": distributorId,
                "asset_classes": assetList
            })
            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/blacklistAssetClass`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get kyc requested by customers list
export function kycRequesters(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get kyc verified customers list
export function kycVerified(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/status/verified`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get complete kyc info of customer
export function customerCompleteKYC(token, customerID) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/${customerID}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get customer statistics
export function getCustomerStatistics(token, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/analytics/dropOffs`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get customer kyc document
export function getCustomerDocuments(token, docType, customerID) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/document/${customerID}/${docType}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc basic detail status
export function updateCustomerBasicStatus(token, customerID, field, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "field": field,
                "distributor_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/distributor/basicDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc bank detail status
export function updateCustomerBankStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "distributor_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/distributor/bankDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update customer kyc basic detail status
export function updateCustomerDematStatus(token, customerID, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "status": status,
                "distributor_id": customerID
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/distributor/dematDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// update customer kyc basic detail status
export function updateCustomerRemark(token, customerID, remark, adminRemark) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "user_remark": remark,
                "distributor_id": customerID,
                "internal_remark": adminRemark
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/kyc/distributor/remark`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update distributor type
export function updateDistributorType(token, customerID, type) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "distributor_id": customerID,
                "type": type
            })
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/type`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update distributor commisionmodel
export function updateDistributorCommisionModel(token, customerID, type, percentage) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "distributor_id": customerID,
                "type": type,
                "percentage": percentage
            })
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/commission`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// upload csv to server
export function uploadCommissionCSV(token, distributorID, file) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = new FormData();
            data.append("payout_sheet", file, "payoutSheet.csv")
            data.append("distributor_id", distributorID)

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/payoutSheet`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// update distributor commision ledger
export function updateDistributorCommisionLedger(token, customerID, credDebt, description) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                "distributor_id": customerID,
                "credit_debit": credDebt,
                "description": description
            })
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/commissionLedger`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get distributor data
export function getDistributorData(token, distributorID, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/analytics/${type}/${distributorID}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get distributor data
export function getDistributorCommissionSheet(token, distributorID) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/analytics/payoutSheet/${distributorID}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get distributor referrer data
export function getDistributorReferrerData(token, distributorID) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/referral/distributor/${distributorID}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// post distributor referrer data
export function postDistributorReferrerData(token, referred_by, referred_to) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = JSON.stringify({
                referred_by: referred_by,
                referred_to: referred_to
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/referral/distributor/manualTagging`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get distributor Commission or payout
export function getDistributorCommissionsPayout(token, payout, status) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/commission${payout && payout === "ledger" ? '/ledger' : payout ? `/payout${status ? "?status=" + status : ""}` : ""}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// distributor initiate commission payout
export function initiateCommissionPayout(token, distributor_id, amount) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/commission/payout`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }, data: JSON.stringify({
                    distributor_id: distributor_id,
                    amount: amount
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// distributor update commission payout
export function updatePayoutStatus(token, payout_id, status, remark) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/commission/payout`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }, data: JSON.stringify({
                    payout_id: payout_id,
                    status: status,
                    remark: remark
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get HDFC sheet
export function downloadHDFCSheet(token, payout_ids) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/commission/payout-hdfc-sheet`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    payout_ids: payout_ids
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get payout invoice 
export function downloadPayoutInvoice(token, payout_ids) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributor/commission/payout/invoice`,
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    payout_id: payout_ids
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create UTM
export function createUTM(token, id, utm) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/distributors/utm`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    distributor_id: id,
                    utm: utm
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}