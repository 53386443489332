import { Modal } from "@material-ui/core"
import { useEffect, useState } from "react"
import { configs, formatCurrency } from "../../../assets/Config"
import { useLogin } from "../../../services/contexts/AdminLoginContext"
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext"
import '../../../assets/styles/BuyOrder.css'
import ToastPage from "../../includes/ToastPage"
import { useNMContext } from "../../../services/contexts/NMContext"


export default function BuyOrderModalPage() {

    const { orderId, utrDetailModal, setUtrDetailModal, saveUTRByOrder, activeStatus, setActiveStatus, completeDematTransferByOrderId, dematTransferModal, setDematTransferModal, payPendStatusModal, setpayPendStatusModal, updatePayPendingStatusByOrderId, getOrderDetailById, manualPayAckModal, setManualPayAckModal, acknowledgeManualPaymentByOrderId, orderDetail, rejectHighValueOrderById, refundHighValueOrderById, completeRefundHighValueOrderById, approveHighValueOrderById, hpRejectModal, setHPRejectModal, hpApproveModal, setHPApproveModal, hpApproveQuantity, setHPApproveQuantity, hpApproveInstrument, setHPApproveInstrument, hpApprovePrice, setHPApprovePrice, getOrdersByType, commentModal, setCommentModal, updateBuyOrderComment, utrBreakupModal, setUtrBreakupModal, hpRefundModal, setHPRefundModal, hpCompleteRefundModal, setHPCompleteRefundModal } = useBuyOrderContext()
    const { changeMessageComment, whatsappResponseMessages } = useNMContext();
    const { setShowSuccess, remarkComments } = useLogin()

    const [utrNumber, setUtrNumber] = useState("")
    const [bankName, setBankName] = useState("")
    const [amount, setAmount] = useState("")
    const [utrList, setUtrList] = useState([])
    const [remark, setRemark] = useState("")
    const [ackCust, setAckCust] = useState("")
    const [ackStatus, setAckStatus] = useState("")
    const [dateTime, setDateTime] = useState(`${new Date(new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19)}`)
    // const [quantity, setQuantity] = useState(hpApproveQuantity)
    const [payPendingstatus, setPayPendingStatus] = useState("")
    const [error, setError] = useState("")

    const [utrHist, setUtrHist] = useState("")

    useEffect(() => {
        if (orderDetail && Array.isArray(orderDetail.payment)) {
            let data = []
            orderDetail.payment.map((element) => {
                element.history.map((element2) => {
                    data.push([element.utr, element.bank_name, element2.order_id, element2.company_name, element2.price, element2.status])
                })
            })
            setUtrHist(data)
            setUtrList(orderDetail.payment)
        }
    }, [orderDetail])

    // add utr function
    function addUtr() {
        try {
            if (!utrNumber.trim() || utrNumber.length > 30) {
                setError("Please enter a valid UTR number.")
            } else if (!bankName.trim()) {
                setError("Please enter a valid Bank Name.")
            } else if (!amount) {
                setError("Please enter a valid Amount.")
            } else if (!dateTime) {
                setError("Please select a valid date time")
            }
            else {
                let utr = utrList
                utr.push({
                    "utr_num": utrNumber,
                    "bank_name": bankName,
                    "payment_date": dateTime,
                    "amount": amount
                })
                setUtrNumber("")
                setDateTime("")
                setAmount("")
                setUtrList(utr)
                setError("")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // delete utr
    function deleteUtr(element) {
        try {
            let utr = <div className="row">
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Number</label>
                        <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                            setUtrNumber(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>Bank Name</label>
                        <select name="user_nationality" id=" user_nationality" className="form-control" value={bankName ? bankName : "default"} onChange={(e) => {
                            setBankName(e.target.value)
                        }}>
                            <option disabled value="default"> Select an option </option>
                            <option value="ICICI">ICICI - 000605028824</option>
                            <option value="HDFC">HDFC - 50200017556819</option>
                            <option value="Cashfree">Cashfree PL</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Amount</label>
                        <input type="number" className="form-control" placeholder="UTR Amount" value={amount ? amount : ""} onChange={(e) => {
                            setAmount(e.target.value)
                        }} />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="form-group">
                        <label>UTR Payment Time</label>
                        <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                            setDateTime(e.target.value)
                        }} />
                    </div>
                </div>
            </div>
            let index = utr.indexOf(element)
            utr.splice(index, 1)
            setUtrList(utr)
            setError("")
        } catch (error) {

        }
    }

    // submit utr details
    async function saveUTRDetails() {
        try {

            document.getElementById("saveUTRBtn").classList.toggle("d-none")
            document.getElementById("saveUTRBtnLoader").classList.toggle("d-none")

            if (!utrList) {
                setError("Please enter a valid UTR number to submit.")
            } else {
                let response = await saveUTRByOrder(orderId, utrList)
                if (response === "success") {
                    setError("")
                    setUtrList([])
                    setUtrDetailModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("saveUTRBtn").classList.toggle("d-none")
            document.getElementById("saveUTRBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // complete demat transfer
    async function completeDematTransfer() {
        try {
            document.getElementById("completeDematBtn").classList.toggle("d-none")
            document.getElementById("completeDematBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid reamrk to complete transfer.")
            } else {
                let response = await completeDematTransferByOrderId(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setDematTransferModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("completeDematBtn").classList.toggle("d-none")
            document.getElementById("completeDematBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // update payment pending status
    async function updatePayStatus() {
        try {
            document.getElementById("updatePayBtn").classList.toggle("d-none")
            document.getElementById("updatePayBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else {
                let response = await updatePayPendingStatusByOrderId(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setPayPendingStatus("")
                    setpayPendStatusModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("updatePayBtn").classList.toggle("d-none")
            document.getElementById("updatePayBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // acknowledge manual payment 
    async function acknowledgePay() {
        try {
            document.getElementById("acknowledgePayBtn").classList.toggle("d-none")
            document.getElementById("acknowledgePayBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else if (!ackCust.trim()) {
                setError("Please enter a valid customer remark to update status.")
            } else if (!ackStatus.trim()) {
                setError("Please select a valid status to update.")
            } else if (utrList.length <= 0) {
                setError("Please enter a valid utr to update.")
            } else {
                let response = await acknowledgeManualPaymentByOrderId(orderId, remark, ackCust, ackStatus, utrList, dateTime)
                if (response === "success") {
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                    setError("")
                    setRemark("")
                    setAckStatus("")
                    setAckCust("")
                    setManualPayAckModal(false)
                    setShowSuccess(true)
                } else {
                    setError(response)
                }
            }

            document.getElementById("acknowledgePayBtn").classList.toggle("d-none")
            document.getElementById("acknowledgePayBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // high value reject
    async function highValueReject() {
        try {
            document.getElementById("highValueRejectBtn").classList.toggle("d-none")
            document.getElementById("highValueRejectBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else {
                let response = await rejectHighValueOrderById(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setHPRejectModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("highValueRejectBtn").classList.toggle("d-none")
            document.getElementById("highValueRejectBtnLoader").classList.toggle("d-none")
        } catch (error) {

        }
    }

    // high value refund
    async function highValueRefund() {
        try {
            document.getElementById("highValueRefundBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else {
                let response = await refundHighValueOrderById(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setHPRefundModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("highValueRefundBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    // complete high value refund
    async function completeHighValueRefund() {
        try {
            document.getElementById("highValueRefundCompleteBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundCompleteBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid remark to update status.")
            } else {
                let response = await completeRefundHighValueOrderById(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setHPCompleteRefundModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("highValueRefundCompleteBtn").classList.toggle("d-none")
            document.getElementById("highValueRefundCompleteBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }

    // high value approve
    async function highValueApprove() {
        try {
            document.getElementById("hightValueApproveBtn").classList.toggle("d-none")
            document.getElementById("hightValueApproveBtnLoader").classList.toggle("d-none")

            const re = /^[0-9]+$/;
            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            if (!hpApproveQuantity.toString().match(re)) {
                setError("Please enter a valid qunatity to update status.")
            } else if (!hpApprovePrice.toString().match(red)) {
                setError("Please enter a valid price  to update status.")
            } else {
                let response = await approveHighValueOrderById(orderId, hpApproveQuantity, hpApprovePrice)
                if (response === "success") {
                    setError("")
                    setHPApproveQuantity("")
                    setHPApprovePrice("")
                    setHPApproveModal(false)
                    setShowSuccess(true)
                    getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("hightValueApproveBtn").classList.toggle("d-none")
            document.getElementById("hightValueApproveBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    // buy order update comment
    async function updateComment() {
        try {
            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")

            if (!remark.trim()) {
                setError("Please enter a valid comment to update.")
            } else {
                let response = orderId && isNaN(orderId) ? await changeMessageComment(orderId.substring(1), remark) : await updateBuyOrderComment(orderId, remark)
                if (response === "success") {
                    setError("")
                    setRemark("")
                    setCommentModal(false)
                    setShowSuccess(true)
                    orderId && isNaN(orderId) ? whatsappResponseMessages() : getOrdersByType(configs.shareStatusTypes[activeStatus].api)
                } else {
                    setError(response)
                }
            }

            document.getElementById("updateCommentBtn").classList.toggle("d-none")
            document.getElementById("updateCommentBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }


    const handleClose = () => {
        setUtrDetailModal(false)
        setDematTransferModal(false)
        setpayPendStatusModal(false)
        setManualPayAckModal(false)
        setHPRejectModal(false)
        setHPRefundModal(false)
        setHPCompleteRefundModal(false)
        setHPApproveModal(false)
        setCommentModal(false)
        setHPApproveQuantity("")
        setHPApprovePrice("")
        setUtrNumber("")
        setBankName("")
        setUtrList("")
        setRemark("")
        setAckCust("")
        setPayPendingStatus("")
        setError("")
        setUtrBreakupModal(false);
    }

    return (
        <>
            {/* // ------------------------manual utr payment detail modal------------------------------- */}
            <Modal open={utrDetailModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">NEFT / RTGS Details for Order - {orderId}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <p className="mt-4 text-custom-grey font-weight-normal">UTR Details</p>
                                <div className="row d-flex m-0">
                                    {utrList.length > 0 ? utrList.map((element, key) => {
                                        return (<div key={`utrList` + key} id={`utrList` + key} className=" col-lg-4 utr-card">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-9">
                                                    <p className="mb-0">
                                                        <span className="text-primary font-weight-bold">{element.utr_num}</span> <br />
                                                        <span className="text-custom-grey">{element.bank_name}</span>
                                                    </p>
                                                </div>
                                                <div className="col-3 text-right">
                                                    <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                        e.preventDefault()
                                                        deleteUtr(element)
                                                        document.getElementById(`utrList` + key).style.display = "none";
                                                    }}><i className="far fa-trash-alt"></i></a>
                                                </div>
                                                <div className="col-6 pr-0">
                                                    <p className="mb-0">{formatCurrency(parseFloat(element.amount))}</p>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0 text-right">{element.payment_date}</p>
                                                </div>
                                            </div>
                                        </div>)
                                    }) : null}
                                </div>
                                <p className="mt-4 text-custom-grey font-weight-normal">Enter UTR Details</p>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Number</label>
                                                <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                                                    setUtrNumber(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <select name="user_nationality" id=" user_nationality" className="form-control" value={bankName ? bankName : "default"} onChange={(e) => {
                                                    setBankName(e.target.value)
                                                }}>
                                                    <option disabled value="default"> Select an option </option>
                                                    <option value="ICICI">ICICI - 000605028824</option>
                                                    <option value="HDFC">HDFC - 50200017556819</option>
                                                    <option value="Cashfree">Cashfree PL</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Amount</label>
                                                <input type="number" className="form-control" placeholder="UTR Amount" value={amount ? amount : ""} onChange={(e) => {
                                                    setAmount(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Payment Time</label>
                                                <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                                                    setDateTime(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            addUtr()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add UTR</button>
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="saveUTRBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    saveUTRDetails()
                                }}>Submit</button>
                                <div id="saveUTRBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div >
            </Modal >

            {/* // ------------------------demat transfer modal------------------------------- */}
            < Modal open={dematTransferModal} onClose={handleClose} style={{ overflow: "scroll" }
            }>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Complete Delivery for Order - {orderId}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <p className="mt-4 text-custom-grey font-weight-normal">Remark to complete Order Delivery</p>
                                <form action="">
                                    <div className="form-group my-1">
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="completeDematBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    completeDematTransfer()
                                }}>Submit</button>
                                <div id="completeDematBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            {/* // ------------------------payment pending order status modal------------------------------- */}
            < Modal open={payPendStatusModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Reject Order - {orderId}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    {/* <div className="form-group">
                                        <label htmlFor="share_status">Select status</label>
                                        <select name="share_status" id="share_status" className="form-control" value={payPendingstatus ? payPendingstatus : "default"} onChange={(e) => {
                                            setPayPendingStatus(e.target.value)
                                        }}>
                                            <option disabled value="default"> Select an option </option>
                                            <option value="rejected">Reject</option>
                                            <option value="expired">Expire</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="updatePayBtn" type="button" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault()
                                    updatePayStatus()
                                }}>Reject</button>
                                <div id="updatePayBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            {/* // ------------------------manual payment acknowledge modal------------------------------- */}
            < Modal open={manualPayAckModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Review Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {orderDetail ? <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <p className="mt-4 text-custom-grey font-weight-normal">UTR Details</p>
                                <div className="row d-flex m-0">
                                    {utrList.length > 0 ? utrList.map((element, key) => {
                                        return (
                                            <div key={`utrList` + key} id={`utrList` + key} className="col-lg-4 utr-card">
                                                {/* <p className="mb-0">
                                                    <span className="text-primary font-weight-bold">{element.utr}</span> <br />
                                                    <span className="text-custom-grey">{element.bank_name}</span>
                                                </p> */}
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-9">
                                                        <p className="mb-0">
                                                            <span className="text-primary font-weight-bold">{element.utr_num}</span> <br />
                                                            <span className="text-custom-grey">{element.bank_name}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                            e.preventDefault()
                                                            document.getElementById(`utrList` + key).style.display = "none";
                                                            deleteUtr(element)
                                                        }}><i className="far fa-trash-alt"></i></a>
                                                    </div>
                                                    <div className="col-6 pr-0">
                                                        <p className="mb-0">{formatCurrency(parseFloat(element.amount))}</p>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <p className="mb-0 text-right">{element.payment_date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Number</label>
                                                <input type="text" className="form-control" placeholder="UTR Number" value={utrNumber ? utrNumber : ""} onChange={(e) => {
                                                    setUtrNumber(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>Bank Name</label>
                                                <select name="user_nationality" id=" user_nationality" className="form-control" value={bankName ? bankName : "default"} onChange={(e) => {
                                                    setBankName(e.target.value)
                                                }}>
                                                    <option disabled value="default"> Select an option </option>
                                                    <option value="ICICI">ICICI - 000605028824</option>
                                                    <option value="HDFC">HDFC - 50200017556819</option>
                                                    <option value="Cashfree">Cashfree PL</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Amount</label>
                                                <input type="number" className="form-control" placeholder="UTR Amount" value={amount ? amount : ""} onChange={(e) => {
                                                    setAmount(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group">
                                                <label>UTR Payment Time</label>
                                                <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                                                    setDateTime(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button className="text-primary custom-button" onClick={(e) => {
                                            e.preventDefault()
                                            addUtr()
                                        }}><i className="fas fa-plus-circle mr-2"></i>Add UTR</button>
                                    </div>
                                </form>

                                <p className="mt-4 text-primary font-weight-normal">Total Payable Amount : <span className="font-weight-bold">{formatCurrency(parseFloat(orderDetail.amount_to_be_paid))}</span></p>
                                {utrHist && utrHist.length > 0 ? <><p className="mt-4 text-custom-grey font-weight-normal">UTR History</p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">UTR No</th>
                                                    <th scope="col">Bank</th>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Company Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {utrHist.map((element, key) => {
                                                    return (
                                                        <tr key={`utrhis` + key}>
                                                            <td>{element[0]}</td>
                                                            <td>{element[1]}</td>
                                                            <td>{element[2]}</td>
                                                            <td>{element[3]}</td>
                                                            <td>{formatCurrency(parseFloat(element[4]))}</td>
                                                            <td className="text-capitalize">{element[5]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </> : null}
                                <form action="">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control" placeholder="Remark" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label >Customer Remark</label>
                                        <textarea className="form-control" placeholder="Remark" value={ackCust ? ackCust : ""} onChange={(e) => {
                                            setAckCust(e.target.value)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Select status</label>
                                        <select name="" id="" className="form-control" required value={ackStatus ? ackStatus : "default"} onChange={(e) => {
                                            setAckStatus(e.target.value)
                                        }}>
                                            <option disabled value="default"> Select an option </option>
                                            <option value="acknowledged">Acknowledged</option>
                                            <option value="generated">Request Payment Again</option>
                                        </select>
                                    </div>
                                    {ackStatus === "acknowledged" ?
                                        <div className="form-group">
                                            <label >Payment Timestamp</label>
                                            <input type="datetime-local" className="form-control" placeholder="Payment Timestamp" value={dateTime} onChange={(e) => {
                                                setDateTime(e.target.value)
                                            }} />
                                        </div>
                                        : null}
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div> : null}
                            <div className="modal-footer justify-content-center">
                                <button id="acknowledgePayBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    acknowledgePay()
                                }}>Submit</button>
                                <div id="acknowledgePayBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            {/* // ------------------------UTR Breakup detail modal------------------------------- */}
            < Modal open={utrBreakupModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">UTR Breakup for order- {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {orderDetail ? <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <p className="mt-4 text-custom-grey font-weight-normal">UTR Details</p>
                                <div className="row d-flex m-0">
                                    {utrList.length > 0 ? utrList.map((element, key) => {
                                        return (
                                            <div key={`utrList` + key} id={`utrList` + key} className="col-lg-4 utr-card">
                                                {/* <p className="mb-0">
                                                    <span className="text-primary font-weight-bold">{element.utr}</span> <br />
                                                    <span className="text-custom-grey">{element.bank_name}</span>
                                                </p> */}
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-9">
                                                        <p className="mb-0">
                                                            <span className="text-primary font-weight-bold">{element.utr_num}</span> <br />
                                                            <span className="text-custom-grey">{element.bank_name}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        <a href="#" className="custom-link text-danger" onClick={(e) => {
                                                            e.preventDefault()
                                                            document.getElementById(`utrList` + key).style.display = "none";
                                                            deleteUtr(element)
                                                        }}><i className="far fa-trash-alt"></i></a>
                                                    </div>
                                                    <div className="col-6 pr-0">
                                                        <p className="mb-0">{formatCurrency(parseFloat(element.amount))}</p>
                                                    </div>
                                                    <div className="col-6 pl-0">
                                                        <p className="mb-0 text-right">{element.payment_date}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                            </div>
                                : null}
                        </div>
                    </div>
                </div>
            </Modal >
            {/* // ------------------------high value reject modal------------------------------- */}
            < Modal open={hpRejectModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Reject Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    <div className="form-group my-1">
                                        <label>Remark</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="highValueRejectBtn" type="button" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault()
                                    highValueReject()
                                }}>Reject</button>
                                <div id="highValueRejectBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            {/* // ------------------------high value refund modal------------------------------- */}
            < Modal open={hpRefundModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Reject Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    <div className="form-group my-1">
                                        <label>Remark</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="highValueRefundBtn" type="button" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault()
                                    highValueRefund()
                                }}>Refund</button>
                                <div id="highValueRefundBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
            
            {/* // ------------------------complete high value refund modal------------------------------- */}
            < Modal open={hpCompleteRefundModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Reject Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    <div className="form-group my-1">
                                        <label>Remark</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }}>
                                            <option value="0"> -- Select a Remark -- </option>
                                            {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                return (
                                                    <option key={`buyorderrejectremark` + key} value={element.template}>{element.template}</option>
                                                )
                                            }) : null}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="highValueRefundCompleteBtn" type="button" className="btn btn-success" onClick={(e) => {
                                    e.preventDefault()
                                    completeHighValueRefund()
                                }}>Complete Refund</button>
                                <div id="highValueRefundCompleteBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            {/* // ------------------------high value approve modal------------------------------- */}
            < Modal open={hpApproveModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Review Order - {orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group">
                                        <label>Instrument - <span className="text-custom-grey">{hpApproveInstrument}</span></label>
                                    </div>
                                </form>
                                <form action="">
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input className="form-control" placeholder="Quantity" value={hpApproveQuantity} onChange={(e) => {
                                            setHPApproveQuantity(e.target.value)
                                        }} />
                                    </div>
                                    <div className="form-group">
                                        <label>Price/Share</label>
                                        <input className="form-control" placeholder="Price/Share" value={hpApprovePrice} onChange={(e) => {
                                            setHPApprovePrice(e.target.value)
                                        }} />
                                    </div>
                                </form>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="addUtrError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="hightValueApproveBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    highValueApprove()
                                }}>Submit</button>
                                <div id="hightValueApproveBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            {/* // ------------------------buy order update comment modal------------------------------- */}
            < Modal open={commentModal} onClose={handleClose} style={{ overflow: "scroll" }}>
                <div className="modal-fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="text-custom-grey h6" id="exampleModalLabel">Update Comment - {orderId && isNaN(orderId) ? orderId.substring(1) : orderId}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => {
                                    e.preventDefault()
                                    handleClose()
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <form action="">
                                    <div className="form-group">
                                        <label>Comment</label>
                                        <textarea className="form-control" placeholder="Comment" value={remark ? remark : ""} onChange={(e) => {
                                            setRemark(e.target.value)
                                        }} />
                                    </div>
                                </form>

                                <div className="form-check p-0">
                                    <p className="text-danger" id="remarkmodalError">{error}</p>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button id="updateCommentBtn" type="button" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault()
                                    updateComment()
                                }}>Update Comment</button>
                                <div id="updateCommentBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >

            <ToastPage />
        </>
    )
}