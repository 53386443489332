import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs, getCookie, setCookie, timeConversion } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import BuyOrderModalPage from '../shareManagement/BuyOrderModalPage';
import ErrorModalPage from '../../includes/ErrorModalPage';
import logo from '../../../assets/images/logo.png';
import { getWhatsappContactMessages, markWhatsappMessageReadByContact, sendWhatsappContactMessages } from '../../../services/apis/notificationManagement/NMAPI';

export default function WhatsAppMessagePage() {

    const { whatsappMessages, whatsappResponseMessages, changeMessageStatus, whatsappContacts, allContacts, setAllContacts, contacts, setContacts } = useNMContext()
    const { setCurrModule, setShowSuccess, allowedFunc, token, setLoginError, logout, getAllRemarkComments, remarkComments } = useLogin()
    const { setShowError } = useKYCContext()
    const { setOrderId, setCommentModal } = useBuyOrderContext()

    const [contactSearch, setContactSearch] = useState("");
    const [activeContact, setActiveContact] = useState("");
    const [message, setMessage] = useState("")
    const [remark, setRemark] = useState("");
    const [messages, setMessages] = useState([]);
    const [rm, setRM] = useState("");
    const [error, setError] = useState("");

    const history = useHistory()

    useEffect(async () => {
        try {
            setCurrModule(configs.modules.whatsappMessage)
            if (allowedFunc.includes(configs.functionList['whatsapp:whatsapp'])) {

                getAllRemarkComments();
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
            setError(error);
            setShowError(true);
        }

    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            whatsappContacts();
        }, 5000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [])


    useEffect(async () => {
        try {
            if (allContacts && allContacts.length > 0) {
                if (parseInt(allContacts[0].timestamp) > parseInt(getCookie("__mst") || 0)) {
                    setCookie(allContacts[0].timestamp)
                    const notification = new Notification("Altius Investech", { body: "You have new messages on WhatsApp!", icon: logo });
                    notification.onclick = (event) => {
                        window.parent.parent.focus();
                        history.push('/whatsappMessages')
                    };

                    if (activeContact && activeContact.phone_number && !allContacts[0].is_msg_responded && allContacts[0].phone_number === activeContact.phone_number) {
                        getMessageByContact(activeContact)
                    }
                }
                filterContact(contactSearch ? { target: { value: contactSearch } } : null)
            }
        } catch (error) {
            console.error(error);
        }
    }, [allContacts])


    async function sendWhatsappMesage(e, alternateMessage) {
        try {
            if ((message && message.trim()) || alternateMessage) {
                let response = await sendWhatsappContactMessages(token, activeContact.name, activeContact.phone_number, alternateMessage ? alternateMessage : message);
                if (response && response.status === 200) {
                    await getMessageByContact(activeContact)
                    document.getElementById("contactList").classList.toggle("d-none");
                    document.getElementById("allMessages").classList.toggle("d-none");
                    whatsappContacts();
                    setMessage("");
                    setRemark()
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setShowError(true);
                    setError(response.data.error);
                }
                let div = document.getElementById("messageList");
                div.scrollTop = div.scrollHeight;
            } else {
                setError("Please enter a valid message.")
                setShowError(true);
                setMessage("");
            }
        } catch (error) {
            console.error(error);
            setError(error)
            setShowError(error)
        }
    }

    async function getMessageByContact(contact) {
        try {
            let response = await getWhatsappContactMessages(token, contact.phone_number)
            if (response && response.status === 200) {
                setMessages(response.data.data);
                setActiveContact(contact);
                setRM(response.data.rm || {})
                document.getElementById("contactList").classList.toggle("d-none");
                document.getElementById("allMessages").classList.toggle("d-none");
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setShowError(true);
                setError(response.data.error);
            }
        } catch (error) {
            console.error(error);
            setShowError(true);
            setError(error);
        }
    }

    async function markMessageAsRead() {
        try {
            let response = await markWhatsappMessageReadByContact(token, activeContact.phone_number)
            if (response && response.status === 200) {
                setMessage("");
                setShowSuccess(true);
                getMessageByContact(activeContact);
                whatsappContacts();
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setShowError(true);
                setError(response.data.error);
            }
        } catch (error) {
            console.error(error);
            setShowError(true);
            setError(error);
        }
    }

    async function filterContact(e) {
        try {
            let tempList = []
            allContacts.forEach(element => {
                if (e && e.target && e.target.value && ((element.name && element.name.toLowerCase().includes(e.target.value.toLowerCase())) || (element.phone_number && element.phone_number.includes(e.target.value)))) {
                    tempList.push(element)
                }
            });
            e && e.target && e.target.value ? setContacts(tempList) : setContacts(allContacts)
        } catch (error) {
            console.error(error);

        }
    }

    async function filterRemark(e) {
        try {
            let tempList = [];
            remarkComments.forEach(element => {
                if (element.template && e.target.value && element.template.toLowerCase().includes(e.target.value.toLowerCase()) && element.master && element.master.function == "whatsapp:send_custom_message") {
                    tempList.push(element)
                }
            })
            e.target.value ? setRemark(tempList) : setRemark();
        } catch (error) {
            console.error(error);
        }
    }


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-2 mr-3">
                            <div className="col-md-9 text-left">
                                <p className="h4 text-custom-grey m-0">WhatsApp Responses</p>
                            </div>
                        </div>
                        <div className={`row align-items-center mt-2 ml-2 mb-2 mr-3 d-md-none ${!activeContact ? "d-none" : "d-flex"}`} onClick={(e) => {
                            setActiveContact("");
                            filterContact()
                            setContactSearch("");
                            document.getElementById("contactList").classList.toggle("d-none");
                            document.getElementById("allMessages").classList.toggle("d-none");
                        }}>
                            <div className='col-2 pr-0'>
                                <p className='h3 m-0' ><i className="fas fa-arrow-circle-left mr1 curToPoint text-primary" aria-hidden="true"></i></p>
                            </div>
                            <div className='col-10 pl-0'>
                                <p className={`text-primary font-weight-bold curPoint d-md-none m-0 p-0`}>{activeContact.name}</p>
                                <p className='f-10 m-0 p-0'>+{activeContact.phone_number}</p>
                            </div>
                        </div>
                        {contacts && contacts.length > 0 ?
                            <div className=" nm-role-table">
                                <div className='row m-0 p-0'>
                                    <div id='contactList' className='col-md-4 col-12 pl-0 ml-0 mr-0 d-md-block'>
                                        <input type='text' className='form-control' placeholder='Search Contact' value={contactSearch} onChange={(e) => {
                                            setContactSearch(e.target.value)
                                            filterContact(e)
                                        }} />
                                        <div className='whtsapp-list mx-0 mt-1'>
                                            {contacts && contacts.length > 0 ?
                                                contacts.map((contact, key) => {
                                                    return (
                                                        <div key={`contact${key}`} className={`curPoint mt-1 px-1 ${activeContact.phone_number === contact.phone_number ? "bg-info" : "bg-light"}`} onClick={(e) => {
                                                            getMessageByContact(contact)
                                                        }}>
                                                            <div className='row m-0 p-0'>
                                                                <div className='col-9 m-0 p-0'>
                                                                    <p className='m-0 p-0 font-weight-bold text-capitalize' style={{ overflow: 'hidden', textOverflow: "ellipsis" }}>{contact.name}</p>
                                                                </div>
                                                                <div className='col-3 m-0 p-0'>
                                                                    {!contact.is_msg_responded ? <div className='msgNoRespond'></div> : null}
                                                                </div>
                                                            </div>
                                                            <div className='row m-0 p-0'>
                                                                <div className='col-6 m-0 p-0'>
                                                                    <p className='m-0 f-12'>+{contact.phone_number}</p>
                                                                </div>
                                                                <div className='col-6 m-0 p-0'>
                                                                    <p className='m-0 f-12 text-right'>{timeConversion(contact.timestamp)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null}
                                        </div>
                                    </div>
                                    {activeContact ?
                                        <div id='allMessages' className='col-md-8 col-12 pl-0 ml-0 mr-0 d-md-block d-none'>
                                            <div className='flex-md-row flex-column' style={{ display: "flex", justifyContent: "space-between", backgroundColor: "antiquewhite" }}>
                                                <p className='m-0'><b>RM:</b> {rm.name}</p>
                                                <p className='m-0' onClick={(e) => {
                                                    e.preventDefault()
                                                    navigator.clipboard.writeText(rm.email);
                                                }}>
                                                    <b>Email:</b> {rm.email} {rm.email ? <i className="far fa-copy text-primary curPoint" ></i> : null}</p>
                                                <p className='m-0' onClick={(e) => {
                                                    e.preventDefault()
                                                    navigator.clipboard.writeText(rm.mobile);
                                                }}>
                                                    <b>Phone:</b> {rm.mobile} {rm.mobile ? <i className="far fa-copy text-primary curPoint" ></i> : null}</p>
                                            </div>
                                            <div id='messageList' className='whtsapp-list bg-light flex-column-reverse mt-1'>
                                                {messages && messages.length > 0 ?
                                                    messages.map((element, key) => {
                                                        return (
                                                            element.type === "SENT" ?
                                                                <div key={`message${key}${new Date()}`} className='whtsapp-right'>
                                                                    <p className='text-primary font-weight-bold text-right mb-0 f-12'>{element.admin.name}</p>
                                                                    <p>{element.message}</p>
                                                                    <p className='text-right f-10 m-0'>{timeConversion(element.timestamp)}</p>
                                                                </div>
                                                                :
                                                                <>
                                                                    {!element.is_msg_responded && element.type === "RECEIVED" && key === 0 ?
                                                                        <div className='separator' onClick={markMessageAsRead}>Mark Conversation as Read</div>
                                                                        : null}
                                                                    <div key={`message${key}`} className='whtsapp-left'>
                                                                        <p>{element.message}</p>
                                                                        <p className='text-right f-10 m-0'>{timeConversion(element.timestamp)}</p>
                                                                    </div>

                                                                </>
                                                        )
                                                    })
                                                    : null}
                                            </div >
                                            <form id='whatsappMessage' onSubmit={sendWhatsappMesage}>
                                                <div className='input-wrapper mt-1'>
                                                    <input type='text' id='whatsappMessageText' className='form-control' value={message} placeholder='Type your Message' onChange={(e) => {
                                                        filterRemark(e)
                                                        setMessage(e.target.value)
                                                    }} />
                                                    {message ?
                                                        <button type='submit' className='text-primary curPoint' onClick={(e) => {
                                                            e.preventDefault();
                                                            sendWhatsappMesage(e);
                                                        }}>
                                                            <i className="fas fa-arrow-circle-right f-22"></i>
                                                        </button> : null}
                                                    {/* // : <button type='submit' className='text-primary curPoint' onClick={(e) => {
                                                        //     e.preventDefault();
                                                        // }}>
                                                        //     <i className="fa fa-plus-circle f-22" aria-hidden="true"></i>
                                                        // </button>} */}
                                                    <div id="whatsapp-suggestion" className={`${remark && remark.length > 0 ? "" : "d-none"}`}>
                                                        {remark && remark.length > 0 ?
                                                            remark.map((element, key) => {
                                                                return (
                                                                    <p className='curPoint m-0' style={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }} onClick={(e) => {
                                                                        sendWhatsappMesage(e, element.template);
                                                                        setRemark()
                                                                    }}>{element.template}</p>
                                                                )
                                                            })
                                                            : null}

                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        : null}
                                </div>

                            </div>
                            : contacts ? <p className='pl-4'>No Data to Show</p> : <div className="loader"></div>}
                    </div>
                </div>
            </div >
            <ToastPage />
            <BuyOrderModalPage />
            <ErrorModalPage error={error} />
        </>
    )
}