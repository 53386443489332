import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { configs, downloadDoc, showDocNewTab } from '../../../assets/Config';
import '../../../assets/styles/KYC.css';
import { addDistributorBlackListedAsset, createUTM, getDistributorBlackListedAsset, getDistributorReferrerData, postDistributorReferrerData, updateCustomerBankStatus, updateCustomerBasicStatus, updateCustomerDematStatus, updateCustomerRemark, updateDistributorCommisionLedger, updateDistributorCommisionModel, updateDistributorType, uploadCommissionCSV } from '../../../services/apis/Distributor/DistributorApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useDistributorContext } from '../../../services/contexts/DistributorContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import ErrorModalPage from '../../includes/ErrorModalPage';
import SuccessModalPage from '../../includes/SuccessModalPage';
import ToastPage from '../../includes/ToastPage';

export default function DistributorReviewRequestDetailPage() {

    const { kycRequesterUserComplete, setKycRequesterUserComplete, getKYCCustomerDetail, panDoc, addressDoc, bankDoc, dematDoc, otherDoc, kycRequesterUserDetail, setKycRequesterUserDetail, kycRequestersList, getKYCRequesters } = useDistributorContext()

    const { setShowError } = useKYCContext()

    const { token, logout, setLoginError, setShowSuccess, allowedFunc, getAllRemarkComments, remarkComments } = useLogin()

    const [remark, setRemark] = useState("")
    const [adminRemark, setAdminRemark] = useState("")
    const [error, setError] = useState("")
    const [update, setUpdate] = useState(false)
    const [addressStatus, setaddressStatus] = useState("")
    const [nameStatus, setnameStatus] = useState("")
    const [panStatus, setpanStatus] = useState("")
    const [bankStatus, setbankStatus] = useState("")
    const [dematStatus, setdematStatus] = useState("")
    const [type, setType] = useState("")
    const [commissionModel, setCommissionModel] = useState("")
    const [commissionRate, setCommissionRate] = useState(0)
    const [file, setFile] = useState("")
    const [credDebt, setCredDebt] = useState("")
    const [amount, setAmount] = useState(0)
    const [desc, setDesc] = useState("")
    const [blacklistedAsset, setBlackListAsset] = useState("")
    const [referred_by, setreferred_by] = useState("")
    const [utm, setUtm] = useState("")


    const history = useHistory()

    let { customerId } = useParams()

    useEffect(() => {
        if (customerId) {
            setKycRequesterUserDetail({ id: customerId })
        }
    }, [])

    // get file contents through file chooser
    function uploadFile(file) {
        try {
            if ((file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel")) {
                setFile(file)
            } else {
                alert("Only excel files are allowed.")
            }
        } catch (error) {

        }
    }

    // get customer kyc detail
    useEffect(() => {
        (async () => {
            try {
                getKYCRequesters()
                let response = await getDistributorBlackListedAsset(token, kycRequesterUserDetail.id)
                if (response && response.status == 200) {
                    setBlackListAsset(response.data)
                } else if (response && response.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage)
                }
                let res = await getDistributorReferrerData(token, customerId)
                if (res && res.status == 200) {
                    setreferred_by(res.data)
                } else if (res && res.status === 401) {
                    logout();
                    setLoginError(configs.unauthorizedErrorMessage)
                }
                if (kycRequesterUserDetail) {
                    await getAllRemarkComments(configs.remarkCategories['kyc:kyc_remark'])
                    let result = await getKYCCustomerDetail();
                    if (result.basic_details) {
                        setaddressStatus(result.basic_details.address_status)
                        setpanStatus(result.basic_details.pan_status)
                        setnameStatus(result.basic_details.name_status)
                        setbankStatus(result.bank_details.status)
                        setdematStatus(result.demat_details.status)
                        setRemark(result.remark)
                        setAdminRemark(result.internal_remark)
                        setType(result.type)
                        setCommissionModel(result.commission_modal)
                        setCommissionRate(result.commission_percentage)
                        setUtm(result.utm && result.utm[0] ? result.utm[0] : utm)
                    }

                }
            } catch (error) {

            }
        })();
    }, [kycRequesterUserDetail])

    //update customer kyc status
    async function updateBasicDetailStatus(field, status) {
        try {
            let response = await updateCustomerBasicStatus(token, kycRequesterUserDetail.id, field, status);
            if (response && response.status === 200) {
                if (field === configs.fields.address) {
                    kycRequesterUserComplete.basic_details.address_status = status
                    setaddressStatus(status)
                }
                else if (field === configs.fields.name) {
                    kycRequesterUserComplete.basic_details.name_status = status
                    setnameStatus(status)
                }
                else if (field === configs.fields.pan) {
                    kycRequesterUserComplete.basic_details.pan_status = status
                    setpanStatus(status)
                }
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    async function updateBankDetailStatus(status) {
        try {
            let response = await updateCustomerBankStatus(token, kycRequesterUserDetail.id, status);
            if (response && response.status === 200) {
                kycRequesterUserComplete.bank_details.status = status
                setbankStatus(status)
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    async function updateDematDetailStatus(status) {
        try {
            let response = await updateCustomerDematStatus(token, kycRequesterUserDetail.id, status);
            if (response && response.status === 200) {
                kycRequesterUserComplete.demat_details.status = status
                setdematStatus(status)
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {

        }
    }

    // save admin remark on customer kyc
    async function updateRemark() {
        try {
            let response = await updateCustomerRemark(token, kycRequesterUserDetail.id, remark, adminRemark);
            if (response && response.status === 200) {
                kycRequesterUserComplete.remark = remark
                kycRequesterUserComplete.adminRemark = adminRemark
                setKycRequesterUserComplete(kycRequesterUserComplete)
                setShowSuccess(true)
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // save distributor type
    async function updateType() {
        try {
            if (!type) {
                setError("Please select a valid Partner type.")
                setShowError(true)
            } else {
                document.getElementById("partnerTypeBtn").classList.toggle("d-none")
                document.getElementById("partnerTypeBtnLoader").classList.toggle("d-none")

                let response = await updateDistributorType(token, kycRequesterUserDetail.id, type);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                    setError(response.data.error)
                    setShowError(true)
                }
                document.getElementById("partnerTypeBtn").classList.toggle("d-none")
                document.getElementById("partnerTypeBtnLoader").classList.toggle("d-none")
            }
        } catch (error) {
            console.error(error)
        }
    }

    // update distributor commission model
    async function updateCommissionModel() {
        try {
            document.getElementById("updateModelBtn").classList.toggle("d-none")
            document.getElementById("updateModelBtnLoader").classList.toggle("d-none")

            if (!commissionModel) {
                setError("Please select a valid Partner type.")
                setShowError(true)
            } else if (commissionModel === "FLAT_RATE" && (parseFloat(commissionRate) <= 0 || parseFloat(commissionRate) > 99)) {
                setError("Commission Rate should be between 0 and 99.")
                setShowError(true)
            }
            else {
                let response = await updateDistributorCommisionModel(token, kycRequesterUserDetail.id, commissionModel, commissionRate);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                    setError(response.data.error)
                    setShowError(true)
                }
            }

            document.getElementById("updateModelBtn").classList.toggle("d-none")
            document.getElementById("updateModelBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    // upload csv to server
    async function uploadCommissionFile() {
        try {
            if (file) {

                document.getElementById("fileBtn").classList.toggle("d-none")
                document.getElementById("fileBtnLoader").classList.toggle("d-none")

                let response = await uploadCommissionCSV(token, kycRequesterUserDetail.id, file);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                    setError("")
                    setFile("")
                } else if (response && response.status === 401) {
                    logout();
                }
                else {
                    setError(response.data.error)
                    setShowError(true)
                }

                document.getElementById("fileBtn").classList.toggle("d-none")
                document.getElementById("fileBtnLoader").classList.toggle("d-none")
            } else {
                setError("Please select a file to upload.")
                setShowError(true)
            }
        } catch (error) {

        }
    }

    // update distributor commission ledger
    async function updateCommissionLedger() {
        try {
            document.getElementById("ledgerBtn").classList.toggle("d-none")
            document.getElementById("ledgerBtnLoader").classList.toggle("d-none")

            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            if (!credDebt) {
                setError("Please select a valid Amount Field type.")
                setShowError(true)
            } else if (!amount || (amount && !amount.toString().match(red))) {
                setError("Please provide a valid amount.")
                setShowError(true)
            } else if (!desc) {
                setError("Please provide a valid description.")
                setShowError(true)
            }
            else {
                let response = await updateDistributorCommisionLedger(token, kycRequesterUserDetail.id, credDebt.toLowerCase() === "credit" ? amount : credDebt.toLowerCase() === "debit" ? `-${amount}` : 0, desc);
                if (response && response.status === 200) {
                    setShowSuccess(true)
                } else if (response && response.status === 401) {
                    logout();
                } else {
                    setError(response.data.error)
                    setShowError(true)
                }
            }

            document.getElementById("ledgerBtn").classList.toggle("d-none")
            document.getElementById("ledgerBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }

    const handleAssetChange = (e, value) => {
        try {
            setBlackListAsset(value.includes("Bucket") ? value.replace("Bucket", "") : value);
        } catch (error) {

        }
    }

    const handleDistributorChange = (e, value) => {
        try {
            setreferred_by(value.id);
        } catch (error) {

        }
    }

    // update blacklist asset
    async function updateAssetBlackList() {
        try {
            document.getElementById("assetBlackListBtn").classList.toggle("d-none")
            document.getElementById("assetBlackListBtnLoader").classList.toggle("d-none")

            let response = await addDistributorBlackListedAsset(token, kycRequesterUserDetail.id, blacklistedAsset);
            if (response && response.status === 200) {
                setShowSuccess(true)
                setError("")
            } else if (response && response.status === 401) {
                logout();
            }
            else {
                setError(response.data.error)
                setShowError(true)
            }

            document.getElementById("assetBlackListBtn").classList.toggle("d-none")
            document.getElementById("assetBlackListBtnLoader").classList.toggle("d-none")

        } catch (error) {

        }
    }

    // update referred by detail
    async function updateDistributorReferrer() {
        try {
            document.getElementById("referrerButton").classList.toggle("d-none")
            document.getElementById("referrerButtonLoader").classList.toggle("d-none")

            let response = await postDistributorReferrerData(token, referred_by, customerId);
            if (response && response.status === 200) {
                setShowSuccess(true)
                setError("")
            } else if (response && response.status === 401) {
                logout();
            }
            else {
                setError(response.data.error)
                setShowError(true)
            }

            document.getElementById("referrerButton").classList.toggle("d-none")
            document.getElementById("referrerButtonLoader").classList.toggle("d-none")

        } catch (error) {

        }
    }

    // update UTM
    async function updateUTM() {
        try {
            document.getElementById("utmManageBtn").classList.toggle("d-none")
            document.getElementById("utmManageBtnLoader").classList.toggle("d-none")

            let response = await createUTM(token, customerId, utm);
            if (response && response.status === 200) {
                setShowSuccess(true)
                setError("")
            } else if (response && response.status === 401) {
                logout();
            }
            else {
                setError(response.data.error)
                setShowError(true)
            }

            document.getElementById("utmManageBtn").classList.toggle("d-none")
            document.getElementById("utmManageBtnLoader").classList.toggle("d-none")

        } catch (error) {

        }
    }

    return (
        <>
            {kycRequesterUserComplete ? <div className="container-fluid">
                <div className="row position-relative px-2">
                    <div className="col-lg-12 m-0 p-0">
                        <p className="h4 text-custom-grey mb-3 mt-4">
                            <span className="mr-3" onClick={(e) => {
                                setKycRequesterUserDetail(null)
                                history.push('/distributor')
                            }}><i className="far fa-arrow-alt-circle-left"></i></span>
                            Review KYC request</p>
                    </div>
                    <div className='col-lg-12 row m-0 p-0 align-items-center'>
                        <div className="col-md-12 col-12 row d-flex flex-wrap kyc-status-bar mb-2 m-0 p-0 pl-1">
                            <div className={nameStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : nameStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={nameStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : nameStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Name</span>
                            </div>
                            <div className={panStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : panStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={panStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : panStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; PAN</span>
                            </div>
                            <div className={addressStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : addressStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={addressStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : addressStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Address</span>
                            </div>
                            <div className={bankStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : bankStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={bankStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : bankStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Bank</span>
                            </div>
                            {/* <div className={dematStatus === configs.statusValues.verified ? "text-success col-lg-2 col-md-3 col-4 m-0 p-0"
                                : dematStatus === configs.statusValues.rejected ? "text-danger col-lg-2 col-md-3 col-4 m-0 p-0"
                                    : "text-primary col-lg-2 col-md-3 col-4 m-0 p-0"}>
                                <i className={dematStatus === configs.statusValues.verified ? "far fa-check-square"
                                    : dematStatus === configs.statusValues.rejected ? "far fa-window-close"
                                        : "far fa-square"}></i>
                                <span>&nbsp; Demat</span>
                            </div> */}
                        </div>
                        <div className='col-md-4 col-12 m-0 p-0 form-group'>
                            <label className='text-secondary'>Blacklist Asset for Distributor</label>
                            <div className='row m-0 p-0'>
                                <div className='col-8 m-0 p-0'>
                                    {configs.assetList ? <Autocomplete
                                        value={blacklistedAsset ? blacklistedAsset : []}
                                        multiple
                                        disableCloseOnSelect
                                        filterSelectedOptions
                                        className="f-12"
                                        id="distName"
                                        placeholder="Asset Name"
                                        getOptionLabel={option => option}
                                        onChange={handleAssetChange}
                                        options={configs.assetList}
                                        renderInput={(params) => <TextField {...params} placeholder="Asset Name" className="f-12" />}
                                    /> : null}
                                </div>
                                <div className='col-4'>
                                    <button id="assetBlackListBtn" className="btn btn-primary px-4" onClick={(e) => {
                                        e.preventDefault();
                                        updateAssetBlackList();
                                    }}> Update</button>
                                    <div id="assetBlackListBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 m-0 p-0 form-group'>
                            <label className='text-secondary'>Referrer Distributor</label>
                            <div className='row m-0 p-0'>
                                <div className='col-8 m-0 p-0'>
                                    {kycRequestersList ? <Autocomplete
                                        value={referred_by ? referred_by : ""}
                                        className="f-12"
                                        id="distName"
                                        placeholder="Distributor Name"
                                        getOptionLabel={option => option.name}
                                        onChange={handleDistributorChange}
                                        options={kycRequestersList.filter(distributor => parseInt(distributor.id) != parseInt(customerId))}
                                        renderInput={(params) => <TextField {...params} placeholder="Distributor Name" className="f-12" />}
                                    /> : null}
                                </div>
                                <div className='col-4'>
                                    <button id="referrerButton" className="btn btn-primary px-4" onClick={(e) => {
                                        e.preventDefault();
                                        updateDistributorReferrer();
                                    }}> Update</button>
                                    <div id="referrerButtonLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12 m-0 p-0 form-group'>
                            <label className='text-secondary'>UTM</label>
                            <div className='row m-0 p-0'>
                                <div className='col-8 m-0 p-0'>
                                    <input type='text' className="form-control" placeholder='Create UTM' value={utm} onChange={(e) => {
                                        setUtm(e.target.value)
                                    }}></input>
                                </div>
                                {kycRequesterUserComplete.utm && kycRequesterUserComplete.utm[0] ? null :
                                    <div className='col-4'>
                                        <button id="utmManageBtn" className="btn btn-primary px-4" onClick={(e) => {
                                            e.preventDefault();
                                            updateUTM();
                                        }}> Update</button>
                                        <div id="utmManageBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="kyc-document-pcontainer">
                        <div className="row py-2 m-0">
                            {allowedFunc.includes(configs.functionList["distributor_management:update_distributor_type"]) ?
                                <div className="col-md-4 pl-0 mt-2">
                                    <form action="">
                                        <div className="form-group my-1">
                                            <label className="text-secondary">Select Partner Type</label>
                                            <select name="" id="" className="col-12 py-1 px-2 border rounded" value={type ? type : ""} onChange={(e) => {
                                                setType(e.target.value)
                                            }}>
                                                <option value="">-- Select Partner Type --</option>
                                                <option value="WEALTH_MANAGER">Wealth Manager</option>
                                                <option value="UTM">UTM</option>
                                                <option value="MARKUP">Markup</option>
                                                <option value="API_CLIENT">API Client</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-4">
                                            <button id="partnerTypeBtn" className="btn btn-primary px-4" onClick={(e) => {
                                                e.preventDefault();
                                                updateType()
                                            }}> Update Partner Type</button>
                                            <div id="partnerTypeBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </form>
                                </div>
                                : null}
                            {allowedFunc.includes(configs.functionList["distributor_management:update_distributor_commission"]) ?
                                <div className="col-md-4 pl-0 mt-2">
                                    <form action="">
                                        <div className="form-group my-1">
                                            <label className="text-secondary">Select Commission Model</label>
                                            <select name="" id="" className="col-12 py-1 px-2 border rounded" value={commissionModel ? commissionModel : ""} onChange={(e) => {
                                                setCommissionModel(e.target.value)
                                            }}>
                                                <option value="">-- Select Commission Model --</option>
                                                <option value="FLAT_RATE">Flat Rate</option>
                                                <option value="CUSTOM">Custom</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-4">
                                            {commissionModel && commissionModel === "CUSTOM" ?
                                                <>
                                                    <button id="updateModelBtn" className="btn btn-primary px-4" onClick={(e) => {
                                                        e.preventDefault();
                                                        updateCommissionModel()
                                                    }}> Update Commission Model</button>
                                                    <div id="updateModelBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                </>
                                                : null}
                                        </div>
                                    </form>
                                </div>
                                : null}
                            <div className="col-md-4 pl-0 mt-2">
                                <form action="">
                                    {commissionModel && commissionModel === "CUSTOM" && allowedFunc.includes(configs.functionList["distributor_management:update_distributor_commission"]) && allowedFunc.includes(configs.functionList["distributor_management:upload_payout_data_sheet"]) ?
                                        <>
                                            <div className="form-group my-1">
                                                <label className="text-secondary">Upload Commission Data Sheet</label>

                                                <div className="file-border rounded py-4" onDragOver={(e) => {
                                                    e.preventDefault();
                                                }} onDrop={(e) => {
                                                    uploadFile(e.dataTransfer.files[0])
                                                    e.preventDefault();
                                                }}>
                                                    <i className="fas fa-file-upload"></i>
                                                    <p className="pb-0 mb-0">
                                                        Drag your file or &nbsp;
                                                        <input type="file" id="upload_userPAN" required hidden accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => {
                                                            uploadFile(e.target.files[0])
                                                        }} />
                                                        <label htmlFor="upload_userPAN" className="mb-0 pb-0">Click here</label>
                                                        &nbsp; to choose <br /> Upload: <span className="text-theme">{file.name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mt-4">
                                                <button id="fileBtn" className="btn btn-primary px-4" onClick={(e) => {
                                                    e.preventDefault();
                                                    uploadCommissionFile()
                                                }}> Update Sheet</button>
                                                <div id="fileBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                            </div>
                                        </>
                                        :
                                        commissionModel && commissionModel === "FLAT_RATE" && allowedFunc.includes(configs.functionList["distributor_management:update_distributor_commission"]) ?
                                            <>
                                                <div className="form-group my-1">
                                                    <label className="text-secondary"> Update Commission Rate (%)</label>
                                                    <input type="number" className="form-control" placeholder='Enter Commission Rate (%)' value={commissionRate} onChange={(e) => {
                                                        e.preventDefault()
                                                        setCommissionRate(e.target.value)
                                                    }} />
                                                </div>
                                                <div className="form-group mt-4">
                                                    <button id="updateModelBtn" className="btn btn-primary px-4" onClick={(e) => {
                                                        e.preventDefault();
                                                        updateCommissionModel()
                                                    }}> Update Commission Rate (%)</button>
                                                    <div id="updateModelBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                </div>
                                            </>
                                            : null}

                                </form>
                            </div>
                        </div>
                        {allowedFunc.includes(configs.functionList["distributor_management:manual_entry_in_commission_ledger"]) ?
                            <div>
                                <div className='row m-0 p-0'>
                                    <div className='col-md-4 col-12 m-0 pl-0'>
                                        <label className="text-secondary">Manual Entry for commission/ Payout</label>
                                        <select name="" id="" className="col-12 py-1 px-2 border rounded" value={credDebt ? credDebt : ""} onChange={(e) => {
                                            setCredDebt(e.target.value)
                                        }}>
                                            <option value="">-- Select Amount Option --</option>
                                            <option value="Credit">Credit</option>
                                            <option value="Debit">Debit</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4 col-12 m-0 pl-0 mt-md-0 mt-2'>
                                        <label className="text-secondary">Amount</label>
                                        <input type="number" className="form-control" placeholder='Enter Amount' value={amount} onChange={(e) => {
                                            e.preventDefault()
                                            setAmount(e.target.value)
                                        }} />
                                    </div>
                                    <div className='col-md-4 col-12 m-0 pl-0 mt-md-0 mt-2'>
                                        <label className="text-secondary">Description</label>
                                        <textarea name="" id="" className="form-control" rows="4" placeholder="Please provide a description" value={desc ? desc : ""} onChange={(e) => {
                                            setDesc(e.target.value)
                                        }}></textarea>
                                        <div className="form-group mt-4">
                                            <button id="ledgerBtn" className="btn btn-primary px-4" onClick={(e) => {
                                                e.preventDefault();
                                                updateCommissionLedger()
                                            }}> Update Commission Ledger</button>
                                            <div id="ledgerBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            : null}
                        <div className='distKYCContainer'>
                            <div className='row m-0 p-0'>
                                <label className="text-secondary">KYC Verification</label>
                            </div>
                            <div className="col-lg-12 my-2 mx-0 pl-0 row kyc-document-container" >
                                <div className="col-md-6 col-12 kyc-document-display">
                                    {panDoc.includes("application/pdf") ? <iframe id="panFrame" height="100%" width="100%"></iframe> : panDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={panDoc} /> : null}
                                    {(panDoc.includes("application/pdf") || panDoc.includes("image")) ?
                                        <div className="kyc-openDoc-container">
                                            <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                                showDocNewTab("Pan Document", panDoc)
                                            }}></i>
                                            <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Pan Document`, panDoc)
                                            }}></i>
                                        </div>
                                        : !kycRequesterUserComplete.distributor_documents.pan_card ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}

                                </div>
                                <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                    <div className="col-12">Password - {kycRequesterUserComplete.distributor_documents.pan_card_password} </div>
                                    <div className="col-6">
                                        <p className="text-secondary">Name Verification</p>
                                        <p className="my-1">{kycRequesterUserComplete.basic_details.name}</p>
                                        {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                            <form className="small mt-3">
                                                <div className="form-group my-1">
                                                    <input type="radio" name="nameVerify" defaultChecked={kycRequesterUserComplete.basic_details.name_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.name, e.target.value)
                                                        //setnameStatus(e.target.value)
                                                    }} /> Verified
                                                </div>
                                                <div className="form-group my-1">
                                                    <input type="radio" name="nameVerify" defaultChecked={kycRequesterUserComplete.basic_details.name_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.name, e.target.value)
                                                        //setnameStatus(e.target.value)
                                                    }} /> Rejected
                                                </div>
                                                <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.name_verified_on} | {kycRequesterUserComplete.basic_details.name_verified_at}</p>
                                                <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.name_verified_by.name}</p>
                                            </form>
                                            : null}
                                    </div>
                                    <div className="col-6">
                                        <p className="text-secondary">PAN Verification</p>
                                        <p className="my-1">{kycRequesterUserComplete.basic_details.pan_number}</p>
                                        {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                            <form className="small mt-3">
                                                <div className="form-group my-1">
                                                    <input type="radio" name="panVerify" defaultChecked={kycRequesterUserComplete.basic_details.pan_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.pan, e.target.value)
                                                        //setpanStatus(e.target.value)
                                                    }} /> Verified
                                                </div>
                                                <div className="form-group my-1">
                                                    <input type="radio" name="panVerify" defaultChecked={kycRequesterUserComplete.basic_details.pan_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.pan, e.target.value)
                                                        //setpanStatus(e.target.value)
                                                    }} /> Rejected
                                                </div>
                                                <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.pan_verified_on} | {kycRequesterUserComplete.basic_details.pan_verified_at}</p>
                                                <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.pan_verified_by.name}</p>
                                            </form>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                                <div className="col-md-6 col-12 kyc-document-display">
                                    {addressDoc.includes("application/pdf") ? <iframe id="addressFrame" height="100%" width="100%"></iframe> : addressDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={addressDoc} /> : null}
                                    {(addressDoc.includes("application/pdf") || addressDoc.includes("image")) ?
                                        <div className="kyc-openDoc-container">
                                            <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                                showDocNewTab("Address Document", addressDoc)
                                            }}></i>
                                            <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Address Document`, addressDoc)
                                            }}></i>
                                        </div>
                                        : !kycRequesterUserComplete.distributor_documents.address_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                                </div>
                                <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                    <div className="col-12">Password - {kycRequesterUserComplete.distributor_documents.address_proof_password}</div>
                                    <div className="col-12">
                                        <p className="text-secondary">Address Verification</p>
                                        <p className="my-1">{kycRequesterUserComplete.basic_details.address}</p>
                                        {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                            <form className="small mt-3">
                                                <div className="form-group my-1">
                                                    <input type="radio" name="addressVerify" defaultChecked={kycRequesterUserComplete.basic_details.address_status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.address, e.target.value)
                                                        //setaddressStatus(e.target.value)
                                                    }} /> Verified
                                                </div>
                                                <div className="form-group my-1">
                                                    <input type="radio" name="addressVerify" defaultChecked={kycRequesterUserComplete.basic_details.address_status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                        updateBasicDetailStatus(configs.fields.address, e.target.value)
                                                        //setaddressStatus(e.target.value)
                                                    }} /> Rejected
                                                </div>
                                                <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.basic_details.address_verified_on} | {kycRequesterUserComplete.basic_details.address_verified_at}</p>
                                                <p><b>Verified by -</b> {kycRequesterUserComplete.basic_details.address_verified_by.name}</p>
                                            </form>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                                <div className="col-md-6 col-12 kyc-document-display">
                                    {bankDoc.includes("application/pdf") ? <iframe id="bankFrame" height="100%" width="100%"></iframe> : bankDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={bankDoc} /> : null}
                                    {(bankDoc.includes("application/pdf") || bankDoc.includes("image")) ?
                                        <div className="kyc-openDoc-container">
                                            <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                                showDocNewTab("Bank Document", bankDoc)
                                            }}></i>
                                            <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Bank Document`, bankDoc)
                                            }}></i>
                                        </div>
                                        : !kycRequesterUserComplete.distributor_documents.bank_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                                </div>
                                <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                    <div className="col-12">Password - {kycRequesterUserComplete.distributor_documents.bank_proof_password}</div>
                                    <div className="col-12">
                                        <p className="text-secondary">Bank Verification</p>
                                        <p className="my-1">Bank Name - {kycRequesterUserComplete.bank_details.bank_name}</p>
                                        <p className="my-1">Account Number - {kycRequesterUserComplete.bank_details.account_number}</p>
                                        <p className="my-1">IFSC Code - {kycRequesterUserComplete.bank_details.ifsc_code}</p>
                                        {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                            <form className="small mt-3">
                                                <div className="form-group my-1">
                                                    <input type="radio" name="bankVerify" defaultChecked={kycRequesterUserComplete.bank_details.status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                        updateBankDetailStatus(e.target.value)
                                                        //setbankStatus(e.target.value)
                                                    }} /> Verified
                                                </div>
                                                <div className="form-group my-1">
                                                    <input type="radio" name="bankVerify" defaultChecked={kycRequesterUserComplete.bank_details.status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                        updateBankDetailStatus(e.target.value)
                                                        //setbankStatus(e.target.value)
                                                    }} /> Rejected
                                                </div>
                                                <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.bank_details.verified_on} | {kycRequesterUserComplete.bank_details.verified_at}</p>
                                                <p><b>Verified by -</b> {kycRequesterUserComplete.bank_details.verified_by.name}</p>
                                            </form>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                            <div className="col-md-6 col-12 kyc-document-display">
                                {dematDoc.includes("application/pdf") ? <iframe id="dematFrame" height="100%" width="100%"></iframe>
                                    : dematDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={dematDoc} /> : null}
                                {(dematDoc.includes("application/pdf") || dematDoc.includes("image")) ?
                                    <div className="kyc-openDoc-container">
                                        <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                            showDocNewTab("Demat Document", dematDoc)
                                        }}></i>
                                        <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                            downloadDoc(`${kycRequesterUserComplete.basic_details.name} Demat Document`, dematDoc)
                                        }}></i>
                                    </div>
                                    : !kycRequesterUserComplete.distributor_documents.demat_proof ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                            </div>
                            <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                <div className="col-12">Password - {kycRequesterUserComplete.distributor_documents.demat_proof_password}</div>
                                <div className="col-12">
                                    <p className="text-secondary">Demat Verification</p>
                                    <p className="my-1">DP ID - {kycRequesterUserComplete.demat_details.dp_id}</p>
                                    <p className="my-1">Client ID - {kycRequesterUserComplete.demat_details.client_id}</p>
                                    {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                        <form className="small mt-3">
                                            <div className="form-group my-1">
                                                <input type="radio" name="dematVerify" defaultChecked={kycRequesterUserComplete.demat_details.status === configs.statusValues.verified ? true : false} className="" value={configs.statusValues.verified} onChange={(e) => {
                                                    updateDematDetailStatus(e.target.value)
                                                    //setdematStatus(e.target.value)
                                                }} /> Verified
                                            </div>
                                            <div className="form-group my-1">
                                                <input type="radio" name="dematVerify" defaultChecked={kycRequesterUserComplete.demat_details.status === configs.statusValues.rejected ? true : false} className="" value={configs.statusValues.rejected} onChange={(e) => {
                                                    updateDematDetailStatus(e.target.value)
                                                    //setdematStatus(e.target.value)
                                                }} /> Rejected
                                            </div>
                                            <p className="mt-3"><b>Verified at -</b> {kycRequesterUserComplete.demat_details.verified_on} | {kycRequesterUserComplete.demat_details.verified_at}</p>
                                            <p><b>Verified by -</b> {kycRequesterUserComplete.demat_details.verified_by.name}</p>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div> */}
                            <div className="col-lg-12 my-2 mx-0 p-0 row kyc-document-container">
                                <div className="col-md-6 col-12 kyc-document-display">
                                    {
                                        otherDoc.includes("application/pdf") ? <iframe id="otherFrame" height="100%" width="100%"></iframe>
                                            : otherDoc.includes("image") ? <img className="docs-box" width="100%" height="100%" src={otherDoc} /> : null
                                    }
                                    {(otherDoc.includes("application/pdf") || otherDoc.includes("image")) ?
                                        <div className="kyc-openDoc-container">
                                            <i className="fa fa-external-link kyc-openDoc curPoint" onClick={() => {
                                                showDocNewTab("Other Document", otherDoc)
                                            }}></i>
                                            <i className="fa fa-download kyc-openDoc curPoint" onClick={() => {
                                                downloadDoc(`${kycRequesterUserComplete.basic_details.name} Other Document`, otherDoc)
                                            }}></i>
                                        </div>
                                        : !kycRequesterUserComplete.distributor_documents.other_document ? <h5 style={{ "margin": "3em" }}>No Document Uploaded</h5> : <div className="loader"></div>}
                                </div>
                                <div className="col-md-6 col-12 row m-0 p-0 kyc-document-status">
                                    <div className="col-12">Password - {kycRequesterUserComplete.distributor_documents.other_document_password}</div>
                                    <div className="col-12">
                                        <p className="text-secondary">Other Document</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 m-0">
                                <div className="col-md-6 pl-0 mt-5">
                                    {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                        <form action="">
                                            <div className="form-group my-1">
                                                <label className="text-secondary">Remark for customer</label>
                                                <select name="" id="" className="col-12 py-1 px-2 border rounded" value={remark ? remark : "0"} onChange={(e) => {
                                                    setRemark(e.target.value)
                                                }}>
                                                    <option value="0"> -- Select a Remark -- </option>
                                                    {remarkComments && remarkComments.templates ? remarkComments.templates.map((element, key) => {
                                                        return (
                                                            <option key={`kycremark` + key} value={element.template}>{element.template}</option>
                                                        )
                                                    }) : null}
                                                </select>
                                            </div>
                                            <div className="form-group my-1">
                                                <textarea name="" id="" className="form-control" rows="4" placeholder="Please write a remark or choose from above" value={remark ? remark : ""} onChange={(e) => {
                                                    setRemark(e.target.value)
                                                }}></textarea>
                                            </div>
                                            <div className="form-group mt-4">
                                                <button className="btn btn-primary px-4" disabled={remark ? false : true} onClick={(e) => {
                                                    e.preventDefault();
                                                    updateRemark()
                                                }}> Submit Remark</button>
                                            </div>
                                        </form>
                                        : null}
                                </div>

                                <div className="col-md-6 pl-0 mt-5">
                                    {allowedFunc.includes(configs.functionList['kyc:update_distributor_kyc_status']) ?
                                        <form action="">
                                            <div className="form-group my-1">
                                                <label className="text-secondary">Internal Remark</label>
                                                <textarea name="" id="" className="form-control" rows="4" placeholder="Please write a remark for internal purpose" value={adminRemark ? adminRemark : ""} onChange={(e) => {
                                                    setAdminRemark(e.target.value)
                                                }}></textarea>
                                            </div>
                                            <div className="form-group mt-4">
                                                <button className="btn btn-primary px-4" disabled={adminRemark ? false : true} onClick={(e) => {
                                                    e.preventDefault();
                                                    updateRemark()
                                                }}> Submit Remark</button>
                                            </div>
                                        </form>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastPage />
                <ErrorModalPage {...{ error: error }} />
            </div >
                : <div className="loader"></div>
            }
        </>
    )
}