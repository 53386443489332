import { Modal, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { uploadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import RichTextEditorPage from '../../includes/RichTextEditorPage';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Autocomplete } from '@material-ui/lab';
import { upsertMandateRequest } from '../../../services/apis/shareManagement/BuyOrdersApi';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';

export default function EditMandatePage() {

    const { setShowSuccess, token, logout, setLoginError, allowedFunc } = useLogin()
    const { kycRequestersList, getKYCRequesters } = useKYCContext()
    const { companyList } = useCMContext();
    const { getAllMandate, mandateData, setMandateData } = useBuyOrderContext();

    const [companyId, setCompanyId] = useState("")
    const [customerId, setCustomerId] = useState("")
    const [quantity, setQuantity] = useState("")
    const [price, setPrice] = useState("")
    const [percent, setPercent] = useState("")
    const [comment, setComment] = useState("")
    const [remark, setRemark] = useState("")
    const [status, setStatus] = useState("GENERATED")
    const [error, setError] = useState("")

    useEffect(() => {
        if (mandateData) {
            try {
                setQuantity(mandateData.quantity)
                setPrice(mandateData.price_per_share)
                setPercent(mandateData.advance_percentage)
                setComment(mandateData.comment)
                setRemark(mandateData.remark)
            } catch (error) {
                console.error(error);

            }
        }
    }, [mandateData])


    useEffect(() => {
        try {
            (async () => {
                await getKYCRequesters()
            })()

        } catch (error) {
            console.error(error)
        }
    }, [])

    const history = useHistory()


    // add company function
    async function updateMandate() {
        try {

            document.getElementById("companyBtn").classList.toggle("d-none")
            document.getElementById("companyBtnLoader").classList.toggle("d-none")

            if (mandateData && !mandateData.id) {
                setError("No valid mandate selected to udpate.")
            } else if (!mandateData && !customerId) {
                setError("Please select a valid customer.");
            } else if (!mandateData && !companyId) {
                setError("Please select a valid company.");
            } else if (!price) {
                setError("Please enter a valid price.");
            } else if (!quantity) {
                setError("Please enter a valid quantity.");
            } else if (!percent) {
                setError("Please select a valid percentage.")
            } else {
                let response = await upsertMandateRequest(token, customerId, companyId, price, quantity, percent, comment, remark, mandateData.id, status)
                if (response && response.status === 200) {
                    resetValues()
                    setShowSuccess(true)
                    getAllMandate()
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response.data.error)
                }

                document.getElementById("companyBtn").classList.toggle("d-none")
                document.getElementById("companyBtnLoader").classList.toggle("d-none")
            }
        } catch (error) {
            console.error(error);
        }
    }

    const resetValues = () => {
        try {
            setCustomerId("");
            setCompanyId("");
            setQuantity("");
            setPrice("");
            setPercent("");
            setComment("");
            setRemark("");
            setError("");
            setMandateData("");
            setStatus("GENERATED");
        } catch (error) {
            console.error(error);

        }
    }

    const handleNameChange = (e, value) => {
        try {
            setCustomerId(value.id)
        } catch (error) {

        }
    }

    const handleCompanyChange = (e, value) => {
        try {
            setCompanyId(value.id)
        } catch (error) {

        }
    }

    // edit mandate ui code
    return (
        <div id="rmCreateCompany" className="col-lg-3 col-md-4 d-md-block d-none pt-3 main-rmPanel">
            <>
                <p className="h6 text-custom-grey">{mandateData ? "Edit Mandate Information" : "Create new Mandate"}</p>
                <form id="createRoleForm" action="" className='mr-1'>
                    {!mandateData ? <div className="form-group">
                        <label className="f-12">Select a customer</label>
                        {kycRequestersList ? <Autocomplete
                            className="f-12"
                            id="emailIP"
                            placeholder="E-mail"
                            getOptionLabel={option => option.email}
                            onChange={handleNameChange}
                            options={kycRequestersList}
                            renderInput={(params) => <TextField {...params} placeholder="E-mail" className="f-12" />}
                        /> : null}
                    </div> : null}
                    {!mandateData ? <div className="form-group">
                        <label className="f-12">Select a Company</label>
                        {companyList ? <Autocomplete
                            className="f-12"
                            id="companyIP"
                            placeholder="Compnay Name"
                            getOptionLabel={option => option.name}
                            onChange={handleCompanyChange}
                            options={companyList}
                            renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                        /> : null}
                    </div> : null}
                    {mandateData ?
                        <div className="form-group">
                            <label className="f-12">Select Status</label>
                            <select className="form-control f-10 py-2" placeholder="Status" value={status} onChange={(e) => {
                                setStatus(e.target.value)
                            }}>
                                <option value="GENERATED">Generated</option>
                                <option value="COMPLETED">Completed</option>
                                <option value="CANCELLED">Cancelled</option>
                            </select>
                        </div>
                        : null}
                    <div className="form-group">
                        <label>Quantity</label>
                        <input type="number" className="form-control f-10 py-2" placeholder="Qty" value={quantity} onChange={(e) => {
                            setQuantity(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Price per Share</label>
                        <input type="number" className="form-control f-10 py-2" placeholder="Price" value={price} onChange={(e) => {
                            setPrice(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Advance Percentage</label>
                        <input type="number" className="form-control f-10 py-2" placeholder="Advance Percentage" value={percent} onChange={(e) => {
                            setPercent(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Comment</label>
                        <input type="text" className="form-control f-10 py-2" placeholder="Comment" value={comment} onChange={(e) => {
                            setComment(e.target.value)
                        }} />
                    </div>
                    <div className="form-group">
                        <label>Remark</label>
                        <input type="text" className="form-control f-10 py-2" placeholder="Remark" value={remark} onChange={(e) => {
                            setRemark(e.target.value)
                        }} />
                    </div>
                </form>
                <div className="form-check p-0">
                    <p className="text-danger" id="createRoleError">{error}</p>
                </div>
                <div className='row m-0 p-0'>
                    <div className='col-9 m-0 p-0'>
                        <button id="companyBtn" className="btn btn-primary f-12" onClick={(e) => {
                            e.preventDefault()
                            updateMandate()
                        }}>{mandateData ? "Update Mandate" : "Add Mandate"}</button>
                        <div id="companyBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                    </div>
                    <div className='col-3 m-0 p-0'>
                        <button className="btn btn-primary f-12" onClick={(e) => {
                            e.preventDefault()
                            resetValues()
                        }}>Reset</button>
                    </div>
                </div>
                <div className='row m-0 p-0'>
                    <button className="btn btn-primary mt-3 d-md-none" onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("rmCreateCompany").classList.toggle('d-none')
                        document.getElementById("companyListPage").classList.toggle('d-none')
                        history.push('/shareManagement/mandate')
                    }}>View All Mandate</button>
                </div>
            </>
        </div>
    )
}