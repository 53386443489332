import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// get all companies list
export function getAllCompanies(token, getDeleted) {
    return new Promise(async (resolve, reject) => {
        try {
            let url = 'companies'
            if (getDeleted) {
                url = 'deletedCompanies'
            }

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get company data by id
export function getCompanyById(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/company/${companyId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update company
export function createUpdateCompany(token, name, secondaryName, faqTitle, industry, sector, unique_title, isin, rta, status, statusMessage, nsdl, cdsl, display_on_home, featured, tagId, disabletrading, logo, metaData, editCompany, companyId, overview, insight, annual_report) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = new FormData();
            data.append("name", name ? name : "")
            data.append("secondary_name", secondaryName ? secondaryName : "")
            data.append("faq_title", faqTitle ? faqTitle : "")
            data.append("industry", industry ? industry : "")
            data.append("sector", sector ? sector : "")
            data.append("unique_title", unique_title ? unique_title : "")
            data.append("isin", isin ? isin : "")
            data.append("status", status ? status : "")
            data.append("listed_on", statusMessage ? statusMessage : "")
            data.append("nsdl", nsdl ? nsdl : "")
            data.append("cdsl", cdsl ? cdsl : "")
            data.append("display_on_home", display_on_home ? display_on_home : "")
            data.append("featured", featured ? featured : "")
            data.append("blog_tag_id", tagId ? tagId : "")
            data.append("disabletrading", disabletrading ? disabletrading : "")
            data.append("logo", logo ? logo : "")
            data.append("overview", overview ? overview : "")
            data.append("insight", insight ? insight : "")
            data.append("annual_report", annual_report ? annual_report : "")
            data.append("rta", rta ? rta : "")

            let urlE = 'createCompany'

            if (editCompany) {
                urlE = 'editCompany'
                data.append("metadata", metaData)
                data.append('company_id', companyId)
            }

            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/${urlE}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create comapny stock summary data
export function addStockData(token, companyId, face_value, book_value, no_of_shares, eps, sales, industry_pe, dividend, equity, pat, message) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                company_id: companyId,
                face_value: face_value,
                book_value: book_value,
                no_of_shares: no_of_shares,
                eps: eps,
                sales: sales,
                industry_pe: industry_pe,
                dividend: dividend,
                equity: equity,
                pat: pat,
                message: message
            }
            var config = {
                method: 'POST',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/stocksummary`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get company stock data by company id
export function getCompanyStockById(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/stocksummary/${companyId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete company
export function deleteCompany(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: companyId
            }
            var config = {
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/company`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore company
export function restoreCompany(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = {
                company_id: companyId
            }
            var config = {
                method: 'PATCH',
                url: `${process.env.REACT_APP_BASEURL}/admin/companymanagement/company`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// upload csv to server
export function uploadCompanyDataDoc(token, file) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = new FormData();
            data.append("company", file, "Company_Input_File.csv")

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/companyParser/updateCompanyData`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// download csv from server
export function downloadCompanyDataDoc(token, companyId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/companyParser/exportCompanyData/${companyId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}