import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { acknowledgeManualPaymentByOrder, approveHighValueOrder, buyOrderComment, completeDematTransferByOrder, completeRefundHighValueOrder, deleteOrder, downloadHDFCSheet, downloadInvoice, downloadSocialMediaImage, downloadSPDSheet, getAllCompanies, getAllMandates, getCCAvenueHistory, getCompanySlabs, getOrderDetail, getOrderListByType, manualOrderPunchCustomerId, refundHighValueOrder, rejectHighValueOrder, restoreOrder, saveUTRByOrderId, updatePayPendingStatusByOrder, getQueryFormData } from '../apis/shareManagement/BuyOrdersApi';
import { useLogin } from './AdminLoginContext';

// create buyOrder management context
export const buyOrderContext = createContext();


export function BuyOrderProvider({ children }) {

    const { token, setLoginError, logout } = useLogin()

    const [orderId, setOrderId] = useState("")
    const [utrDetailModal, setUtrDetailModal] = useState(false)
    const [dematTransferModal, setDematTransferModal] = useState(false)
    const [payPendStatusModal, setpayPendStatusModal] = useState(false)
    const [utrBreakupModal, setUtrBreakupModal] = useState(false)
    const [manualPayAckModal, setManualPayAckModal] = useState(false)
    const [hpApproveModal, setHPApproveModal] = useState(false)
    const [hpRejectModal, setHPRejectModal] = useState(false)
    const [hpRefundModal, setHPRefundModal] = useState(false)
    const [hpCompleteRefundModal, setHPCompleteRefundModal] = useState(false)
    const [hpApproveQuantity, setHPApproveQuantity] = useState("")
    const [hpApprovePrice, setHPApprovePrice] = useState("")
    const [hpApproveInstrument, setHPApproveInstrument] = useState("")
    const [orderDetail, setOrderDetail] = useState("")
    const [update, setUpdate] = useState(false)
    const [commentModal, setCommentModal] = useState(false)
    const [activeStatus, setActiveStatus] = useState()
    const [tableData, setTableData] = useState("")
    const [tableMandateData, setTableMandateData] = useState("")
    const [mandateData, setMandateData] = useState("");
    const [queryData, setQueryData] = useState("");


    // function to get orders by type
    async function getOrdersByType(type) {
        try {
            let response = await getOrderListByType(token, type)
            if (response && response.status === 200) {
                setTableData(response.data)
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else { return response.data.error }
        } catch (error) {

        }
    }

    // save utr detail by order id
    async function saveUTRByOrder(orderId, utrList) {
        try {
            let response = await saveUTRByOrderId(token, orderId, utrList);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // complete demat transfer
    async function completeDematTransferByOrderId(orderId, remark) {
        try {
            let response = await completeDematTransferByOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update payment pending order status
    async function updatePayPendingStatusByOrderId(orderId, remark) {
        try {
            let response = await updatePayPendingStatusByOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get order detail by order id
    async function getOrderDetailById(orderId) {
        try {
            let response = await getOrderDetail(token, orderId);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // acknowledge manual payment order
    async function acknowledgeManualPaymentByOrderId(orderId, remark, customerRemark, status, utrList, dateTime) {
        try {
            let response = await acknowledgeManualPaymentByOrder(token, orderId, remark, customerRemark, status, utrList, dateTime);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // reject high value order
    async function rejectHighValueOrderById(orderId, remark) {
        try {
            let response = await rejectHighValueOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // refund high value order
    async function refundHighValueOrderById(orderId, remark) {
        try {
            let response = await refundHighValueOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);

        }
    }

    // complete refund high value order
    async function completeRefundHighValueOrderById(orderId, remark) {
        try {
            let response = await completeRefundHighValueOrder(token, orderId, remark);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);

        }
    }


    // approve high value order
    async function approveHighValueOrderById(orderId, quantity, price) {
        try {
            let response = await approveHighValueOrder(token, orderId, quantity, price, update);
            if (response && response.status === 200) {
                setUpdate(false)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete order
    async function deleteOrderById(orderId) {
        try {
            let response = await deleteOrder(token, orderId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // restore order
    async function restoreOrderById(orderId) {
        try {
            let response = await restoreOrder(token, orderId);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all companies
    async function getCompanyList() {
        try {
            let response = await getAllCompanies(token);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all slbas by companies
    async function getCompanySlabsByCompanyId(companyId) {
        try {
            let response = await getCompanySlabs(token, companyId);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all slbas by companies
    async function manualOrderPunch(userId, companyId, price, quantity, distributorId, upmark, upmark_final_pay) {
        try {
            let response = await manualOrderPunchCustomerId(token, userId, companyId, price, quantity, distributorId, upmark, upmark_final_pay);
            if (response && response.status === 200) {
                return {
                    "success": "success",
                    "data": response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // async function download invoice
    async function downloadInvoiceByOrderId(orderId) {
        try {
            let response = await downloadInvoice(token, orderId);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = `Invoice_Order_${orderId}.pdf`; //File name Here
                a.click();
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else { }
        } catch (error) {
            console.error(error)
        }
    }

    // function to download SPD sheet
    async function downloadSPDSheetByOrderId(orderIds) {
        try {
            let response = await downloadSPDSheet(token, orderIds);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data.file; //Image Base64 Goes here
                a.download = response.data.fileName; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error;
            }
        } catch (error) {
            console.error(error)
        }
    }

    // function to download HDFC sheet
    async function downloadHDFCSheetByOrderId(orderIds) {
        try {
            let response = await downloadHDFCSheet(token, orderIds);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data.file; //Image Base64 Goes here
                a.download = response.data.fileName; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error;
            }
        } catch (error) {
            console.error(error)
        }
    }

    // async download social media image
    async function downloadSocialMediaImageByCompany(companyId, date, title, description) {
        try {

            let response = await downloadSocialMediaImage(token, companyId, date, title, description);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = `${title}.pdf`; //File name Here
                a.click();

                return { success: "success" }
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                return response.data.error
            }
        } catch (error) {
            console.error(error)
        }
    }

    // update buy order comment
    async function updateBuyOrderComment(orderId, comment) {
        try {
            let response = await buyOrderComment(token, orderId, comment);
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // get all mandate
    async function getAllMandate() {
        try {
            let response = await getAllMandates(token)
            if (response && response.status === 200) {
                setTableMandateData(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }
    // get ccavenue history by order id
    async function getCCAvenueHistoryByOrderId(orderId) {
        try {
            let response = await getCCAvenueHistory(token, orderId)
            if (response && response.status === 200) {
                return {
                    success: true,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {
            console.error(error);
        }
    }

    // get all query form data
    async function getAllQueryData() {
        try {
            let response = await getQueryFormData(token)
            if (response && response.status === 200) {
                setQueryData(response.data);
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    return (
        <buyOrderContext.Provider
            value={{
                tableData,
                setTableData,
                orderId,
                setOrderId,
                getOrdersByType,
                utrDetailModal,
                setUtrDetailModal,
                saveUTRByOrder,
                activeStatus,
                setActiveStatus,
                dematTransferModal,
                setDematTransferModal,
                completeDematTransferByOrderId,
                payPendStatusModal,
                setpayPendStatusModal,
                updatePayPendingStatusByOrderId,
                orderDetail,
                setOrderDetail,
                getOrderDetailById,
                manualPayAckModal,
                setManualPayAckModal,
                acknowledgeManualPaymentByOrderId,
                hpRejectModal,
                setHPRejectModal,
                rejectHighValueOrderById,
                refundHighValueOrderById,
                completeRefundHighValueOrderById,
                setUpdate,
                hpApproveModal,
                setHPApproveModal,
                approveHighValueOrderById,
                hpApproveQuantity,
                setHPApproveQuantity,
                hpApprovePrice,
                setHPApprovePrice,
                hpApproveInstrument,
                setHPApproveInstrument,
                getCompanyList,
                getCompanySlabsByCompanyId,
                manualOrderPunch,
                deleteOrderById,
                restoreOrderById,
                downloadInvoiceByOrderId,
                downloadSPDSheetByOrderId,
                downloadSocialMediaImageByCompany,
                commentModal,
                setCommentModal,
                updateBuyOrderComment,
                utrBreakupModal,
                setUtrBreakupModal,
                getAllMandate,
                mandateData,
                setMandateData,
                tableMandateData,
                setTableMandateData,
                hpRefundModal,
                setHPRefundModal,
                hpCompleteRefundModal,
                setHPCompleteRefundModal,
                downloadHDFCSheetByOrderId,
                getCCAvenueHistoryByOrderId,
                queryData,
                setQueryData,
                getAllQueryData
            }}
        >
            {children}
        </buyOrderContext.Provider>)

}

export function useBuyOrderContext() {
    return useContext(buyOrderContext)
}
