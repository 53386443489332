import { Modal, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/RM.css'
import { useLogin } from '../../../../services/contexts/AdminLoginContext';
import DeleteModalPage from '../../../includes/DeleteModalPage';
import { useCRMContext } from '../../../../services/contexts/CRMContext';
import { useKYCContext } from '../../../../services/contexts/KYCContext';
import { Link } from 'react-router-dom';
import { useRMContext } from '../../../../services/contexts/RMContext';
import { Autocomplete } from '@material-ui/lab';

export default function UniqueEntryPage() {

    const { remarkList, setRemarkList, getAllRemarkListByUser, downloadFile, setEditRemark, setEditRemarkData, editNewsData, getNewsDataById, setShowDeletedRemark, showDeletedRemark, getAllRemarkList, getNoRMUserList, getAllRemarkListByRM, remarkListType, setRemarkListType, setCustomer } = useCRMContext()
    const { setShowDelete, setShowSuccess, allowedFunc, adminId } = useLogin()
    const { setKycRequesterUserDetail, kycRequestersList } = useKYCContext()
    const { adminList } = useRMContext()

    const history = useHistory()

    const [deleteId, setDeleteId] = useState({})
    const [userName, setUserName] = useState("")
    const [leadFilter, setLeadFilter] = useState(adminId);
    const [filteredLeads, setFilteredLeads] = useState([]);

    const columns = [{ field: "id", headerName: "Remark ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    {
        field: "cust_name", headerName: "Customer Name", minWidth: 50, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return <p className='MuiTableCellEllipsis'>{params.row.user ? params.row.user.name : params.row.name}</p>
        }, valueGetter: (params) => params.row.user ? params.row.user.name : params.row.name
    },
    {
        field: "company_name", headerName: "Company Name", minWidth: 110, flex: 0.1, cellClassName: "break-text MuiTableCellEllipsis", renderCell: (params) => {
            return <p className='MuiTableCellEllipsis'>{params.row.company_name}</p>
        }
    },
    { field: "order_type", headerName: "Order Type", minWidth: 60, flex: 0.1, cellClassName: "break-text" },
    { field: "quantity", headerName: "Quantity", minWidth: 90, flex: 0.1, cellClassName: "break-text" },
    { field: "client_quote", headerName: "Client Quote", minWidth: 90, flex: 0.1, cellClassName: "break-text" },
    { field: "our_quote", headerName: "Our Quote", minWidth: 90, flex: 0.1, cellClassName: "break-text" },
    // { field: "price_per_share", headerName: "Rate/Share", minWidth: 90, flex: 0.1, cellClassName: "break-text" },
    {
        field: "rm_name", headerName: "Relationship Manager", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.relationship_manager ? params.row.relationship_manager.name : ""
        }, valueGetter: (params) => params.row.relationship_manager ? params.row.relationship_manager.name : ""
    },
    {
        field: "rm_id", headerName: "Relationship Manager", minWidth: 50, flex: 0.4, cellClassName: "break-text", hide: true, renderCell: (params) => {
            return params.row.relationship_manager ? params.row.relationship_manager.id : ""
        }, valueGetter: (params) => params.row.relationship_manager ? params.row.relationship_manager.id : ""
    },
    { field: "remark", headerName: "Remark", minWidth: 150, flex: 0.5, cellClassName: "break-text" },
    {
        field: "latest_admin", headerName: "Last Remark By", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.latest_admin ? params.row.latest_admin.name : ""
        }, valueGetter: (params) => params.row.latest_admin ? params.row.latest_admin.name : ""
    },
    {
        field: "view", headerName: " ", minWidth: 60, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            if (params.row.status === "OPEN")
                return <div className='orderInsightNotiDiv'>
                    <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setEditRemark(true);
                        setEditRemarkData(params.row);
                        document.getElementById("rmCreateNews").classList.toggle('d-none')
                        document.getElementById("newsListPage").classList.toggle('d-none')
                    }}>Edit</a>
                    {params.row.latest_admin && params.row.latest_admin.id && params.row.latest_admin.id != adminId ? <div></div> : null}
                </div>
        }
    },
    { field: "date", headerName: "Created Date", minWidth: 50, flex: 0.4, cellClassName: "break-text" },
    { field: "updated_at", headerName: "Modified Date", minWidth: 50, flex: 0.4, cellClassName: "break-text" },
    { field: "is_created_by_user", headerName: "Creator", minWidth: 50, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
        return params.row.is_created_by_user ? "Customer": "Admin"
    }, valueGetter: (value) => value.row.is_created_by_user ? `Customer` : "Admin" },
    {
        field: "mobile", headerName: "Mobile No.", minWidth: 150, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.mobile ? <a href={"https://wa.me/" + params.row.mobile} target="_blank">+{params.row.mobile}</a> : ""
        }, valueGetter: (value) => value.row.mobile ? `+${value.row.mobile}` : value.row.mobile
    },

    // { field: "approved_quote", headerName: "Approved Quote", minWidth: 110, flex: 0.1, cellClassName: "break-text" },
    { field: "status", headerName: "Status", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    {
        field: "cust_email", headerName: "Customer Email", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user ? params.row.user.id : params.row.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row)
                }}>{params.row.user ? params.row.user.email : params.row.email}</Link>
            } else {
                return params.row.user ? params.row.user.email : params.row.email
            }
        }, valueGetter: (params) => params.row.user ? params.row.user.email : params.row.email
    }

    ]

    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                if (remarkListType === "all")
                    await getAllRemarkList("order-insight")
                else
                    await getAllRemarkList(`order-insight/status/${remarkListType}`)
            } catch (error) {
                console.error(error);
            }
        }, 3000);
        return () => clearInterval(intervalId);
    }, [remarkListType])

    useEffect(() => {
        if (remarkList && remarkList.length > 0 && leadFilter && parseInt(leadFilter) === parseInt(adminId)) {
            let tempList = remarkList.filter((remark) => {
                if (remark.admin_id === adminId || remark.relationship_manager.id === adminId)
                    return remark
            })
            setFilteredLeads(tempList);
        } else {
            setFilteredLeads(remarkList);
        }
    }, [leadFilter, remarkList])

    // role list page ui code
    return (
        <div id="newsListPage" className='d-md-block d-none' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-2 mb-1 mr-3">
                <div className="col-lg-6 col-6 m-0 p-0">
                    <p className="h4 text-custom-grey">Remark Management</p>
                </div>
                <div className="col-lg-6 col-6 text-right m-0 p-0 filterArea">
                    <select className='' id="selectRemarkData" value={remarkListType} onChange={(e) => {
                        e.preventDefault()
                        setRemarkList("")
                        setRemarkListType(e.target.value)
                    }}>
                        <option value="all">All Remarks</option>
                        <option value="OPEN">Open</option>
                        <option value="PROCESSED">Processed</option>
                        <option value="REJECTED_BY_ALTIUS">Rejected by Altius</option>
                        <option value="REJECTED_BY_USER">Rejected by Investor</option>
                    </select>
                    <select className='' id="adminFilter" value={leadFilter} onChange={(e) => {
                        e.preventDefault()
                        setLeadFilter(e.target.value)
                    }}>
                        <option value={adminId}>My Leads</option>
                        <option value={0}>All Leads</option>
                    </select>
                </div>
            </div>
            <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                <a href='#' className='custom-link' onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                }}><p className='m-0 p-0'>Add New Remark</p></a>
            </div>
            {filteredLeads ?
                <>
                    <div className="nm-role-table m-2 d-none d-md-block">
                        <DataGrid
                            rows={filteredLeads}
                            headerHeight={30}
                            rowHeight={40}
                            columns={columns}
                            resizable={true}
                            autoPageSize
                            pagination
                            components={{
                                Toolbar: GridToolbar,
                            }}

                        // onSelectionModelChange={onRowSelected}
                        />
                    </div>
                    <div className="nm-role-table m-2 d-md-none d-block" style={{ overflow: 'scroll' }}>
                        {filteredLeads.map(element => {
                            return (
                                <div className={`card p-1 `} onClick={(e) => {
                                    if (element.status === "OPEN") {
                                        setEditRemark(true);
                                        setEditRemarkData(element);
                                        document.getElementById("rmCreateNews").classList.toggle('d-none')
                                        document.getElementById("newsListPage").classList.toggle('d-none')
                                    }
                                }}>
                                    <div className='row m-0 p-0'>
                                        <div className='col-6 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'><b>{element.name}</b></p>
                                        </div>
                                        <div className='col-6 m-0 p-0 text-right'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>{element.date}</p>
                                        </div>
                                    </div>
                                    <p className='mb-1 p-0 f-12'>{element.company_name}</p>
                                    <div className='row m-0 p-0'>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Quantity</p>
                                        </div>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Rate/Share</p>
                                        </div>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Order Type</p>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0'>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{formatNumber(parseFloat(element.quantity))}</p>
                                        </div>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{formatCurrency(parseFloat(element.price_per_share))}</p>
                                        </div>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{element.order_type}</p>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0'>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Status</p>
                                        </div>
                                        <div className='col-8 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Remark</p>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0'>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{element.status}</p>
                                        </div>
                                        <div className='col-8 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{element.remark}</p>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0'>
                                        <div className='col-8 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'>Relationship Manager</p>
                                        </div>
                                        <div className='col-4 m-0 p-0'>
                                            <p className='m-0 p-0 f-12 font-weight-bold'></p>
                                        </div>
                                    </div>
                                    <div className='row m-0 p-0'>
                                        <div className='col-8 m-0 p-0'>
                                            <p className='m-0 p-0 f-12'>{element.relationship_manager.name}</p>
                                        </div>
                                        {element.status === "OPEN" ?
                                            <div className='col-4 m-0 p-0'>
                                                <p className='m-0 p-0 f-12 text-right'><a href="#" className="custom-link text-primary" onClick={(e) => {
                                                    e.preventDefault()
                                                    setEditRemark(true);
                                                    setEditRemarkData(element);
                                                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                                                    document.getElementById("newsListPage").classList.toggle('d-none')
                                                }}>Edit</a></p>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />

        </div>
    )
}