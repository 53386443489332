import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import '../../../assets/styles/NM.css'
import { useNMContext } from '../../../services/contexts/NMContext';
import ToastPage from '../../includes/ToastPage';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';

export default function QueryFormResponsePage() {

    const { setCurrModule, setShowSuccess, allowedFunc } = useLogin()
    const { getAllQueryData, queryData } = useBuyOrderContext();
    const { setKycRequesterUserDetail } = useKYCContext()


    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                setCurrModule(configs.modules.queryForm)
                // if (allowedFunc.includes(configs.functionList['notification_management:notification_management'])) {
                getAllQueryData()
                // } else {
                //     return history.goBack()
                // }
            } catch (error) {

            }
        })()
    }, [])


    const columns = [{ field: "id", headerName: "Query ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    {
        field: "companyISIN", headerName: "Company ISIN", minWidth: 200, flex: 0.5, hide: true, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company && params.row.company.isin ? params.row.company.isin : ""
        }, valueGetter: (params) => params.row.company && params.row.company.isin ? params.row.company.isin : ""
    },
    {
        field: "companyID", headerName: "Company ID", minWidth: 200, flex: 0.5, hide: true, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company && params.row.company.id ? params.row.company.id : ""
        }, valueGetter: (params) => params.row.company && params.row.company.id ? params.row.company.id : ""
    },
    {
        field: "companyName", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company && params.row.company.name ? params.row.company.name : ""
        }, valueGetter: (params) => params.row.company && params.row.company.name ? params.row.company.name : ""
    },
    { field: "quantity", headerName: "Quantity", minWidth: 80, flex: 0.3, filterable: false },
    { field: "trade", headerName: "Order Type", minWidth: 80, flex: 0.3, filterable: false },
    { field: "price_per_share", headerName: "Price/Share", minWidth: 80, flex: 0.3, filterable: false },
    { field: "remark", headerName: "Remark", minWidth: 200, flex: 0.5, filterable: false },
    { field: "timestamp", headerName: "Timestamp", minWidth: 200, flex: 0.5, filterable: false },
    {
        field: "name", headerName: "Customer Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
            return params.row.user.name
        }
    },
    {
        field: "mobile", headerName: "Customer Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
        }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
    },
    {
        field: "email", headerName: "Customer E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row.user)
                }}>{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    {
        field: "rm_name", headerName: "RM Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row && params.row.relationship_manager && params.row.relationship_manager.name ? params.row && params.row.relationship_manager && params.row.relationship_manager.name : ""
        }, valueGetter: (params) => params.row && params.row.relationship_manager && params.row.relationship_manager.name ? params.row && params.row.relationship_manager && params.row.relationship_manager.name : ""
    },
    {
        field: "rm_email", headerName: "RM Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row && params.row.relationship_manager && params.row.relationship_manager.email ? params.row && params.row.relationship_manager && params.row.relationship_manager.email : ""
        }, valueGetter: (params) => params.row && params.row.relationship_manager && params.row.relationship_manager.email ? params.row && params.row.relationship_manager && params.row.relationship_manager.email : ""
    }
    ]


    // notification management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='nmContainer' className="nm-container">
                    <div style={{ width: "100%" }}>
                        <div className="row d-flex align-items-center mt-2 ml-2 mb-4 mr-3">
                            <div className="col-md-6 text-left">
                                <p className="h4 text-custom-grey">Query Data</p>
                            </div>

                        </div>
                        <div className=" nm-role-table">
                            {queryData ? <DataGrid
                                rows={queryData}
                                headerHeight={40}
                                rowHeight={30}
                                columns={columns}
                                resizable={true}
                                autoPageSize
                                pagination
                                components={{
                                    Toolbar: GridToolbar,
                                }} />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <ToastPage />
            </div>
        </>
    )
}