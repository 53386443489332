import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';
import { getCompanyOpenOrders, getInventoryAnalytics, saveProcessedSheetData } from '../../../services/apis/shareManagement/InventoryAPI';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Link } from 'react-router-dom';

export default function DInventoryAnalyticsPage() {
    const { processedData, setProcessedData, showReport, setShowReport } = useCMContext();
    const { setShowSuccess, token, logout, setLoginError, setCurrModule, allowedFunc } = useLogin()

    const [error, setError] = useState("");

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['depository_parser:depository_parser'])) {
                    setCurrModule(configs.modules.depository);
                } else {

                }
            } catch (error) {

            }
        })()
    }, [])


    //save data to server
    async function saveDataToServer() {
        try {
            document.getElementById("saveDataBtn").classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")

            let response = await saveProcessedSheetData(token);

            if (response && response.status === 200) {
                setShowSuccess(true)
                setError("")
                setProcessedData([]);
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                setError(response.data.error)
                setProcessedData([])
            }

            document.getElementById("saveDataBtn").classList.toggle("d-none")
            document.getElementById("saveDataBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error);
        }
    }


    const columns = [{ field: "id", headerName: "Row Id", minWidth: 150, flex: 0.5, hide: true, filterable: false },
    {
        field: "isin", headerName: "ISIN", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company.isin
        }, valueGetter: (value) => value.row.company.isin
    },
    {
        field: "asset_class", headerName: "Asset Class", minWidth: 150, flex: 0.5, hide: true, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company.asset_class
        }, valueGetter: (value) => value.row.company.asset_class
    },
    {
        field: "company_name", headerName: "Company Name", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.company.name
        }, valueGetter: (value) => value.row.company.name
    },
    {
        field: "action_date", headerName: "Action Date", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.toUpdate.action_date
        }, valueGetter: (value) => value.row.toUpdate.action_date
    },
    {
        field: "depository_reference_number", headerName: "Depository Reference Number", minWidth: 100, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.toUpdate.depository_reference_number
        }, valueGetter: (value) => value.row.toUpdate.depository_reference_number
    },
    {
        field: "order_id", headerName: "Order Id", minWidth: 90, flex: 0.2, cellClassName: "break-text", renderCell: (params) => {
            return params.row.order.id
        }, valueGetter: (value) => value.row.order.id
    },
    {
        field: "quantity", headerName: "Quantity", minWidth: 90, flex: 0.2, cellClassName: "break-text text-right", renderCell: (params) => {
            return params.row.order.quantity
        }, valueGetter: (value) => value.row.order.quantity
    },
    {
        field: "client_id", headerName: "Client Id", minWidth: 100, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return params.row.user.client_id
        }, valueGetter: (value) => value.row.user.client_id
    },
    { field: "row_status", headerName: "Row Status", minWidth: 80, flex: 0.3, cellClassName: "break-text" },
    { field: "message", headerName: "Message", minWidth: 250, flex: 0.5, cellClassName: "break-text" },
    {
        field: "client_email", headerName: "E-mail", minWidth: 250, flex: 0.5, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} >{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    ]

    return (
        <div id="inventoryAnalyticsListPage" style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-md-6 col-8 p-0">
                    <p className="h4 text-custom-grey">Depository Update</p>
                </div>
                {processedData && processedData.length > 0 ?
                    <div className="col-md-6 col-4 p-0">
                        <a id="saveDataBtn" href="#" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            saveDataToServer()
                        }}><i className="fas fa-upload mr-2"></i>Save Data</a>
                        <div className='d-flex justify-content-center'>
                            <div id="saveDataBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                        </div>
                    </div>
                    : null}
            </div>
            <div className='row m-0 mx-3 p-0'>
                <div className='col-6 m-0 p-0'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        setShowReport(true);
                    }}><p className='m-0 p-0'>View Reports</p></a>
                </div>
                <div className='col-6 m-0 p-0 d-md-none d-block'>
                    <a href='#' className='custom-link' onClick={(e) => {
                        e.preventDefault()
                        document.getElementById("updateExportInventory").classList.toggle('d-none')
                        document.getElementById("inventoryAnalyticsListPage").classList.toggle('d-none')
                    }}><p className='m-0 p-0'>Update Depository Data</p></a>
                </div>
            </div>
            <div className="deleted-role-table">
                {processedData && processedData.length > 0 ? <DataGrid
                    rows={processedData}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }} /> : <p className={`pt-5 ${error ? "text-danger" : ""}`}>{error ? error : "No Processed Data to review."}</p>}
            </div>
        </div>
    )
}