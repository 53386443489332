import axios from "axios";
import { configs } from "../../../assets/Config";


// get all admins
export function getAllAdmins(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/allAdmins`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create admin
export function addAdmin(token, name, email, password, roleId, mobile) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "name": name,
                "email": email,
                "password": password,
                "role_id": roleId,
                "mobile": mobile
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/createAdminAccount`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// edit admin
export function editAdmin(token, name, email, password, roleId, mobile, adminId) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "name": name,
                "email": email,
                "password": password,
                "role_id": roleId,
                "admin_id": adminId,
                "mobile": mobile
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/editAdminDetails`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// delete admin
export function deleteAdminById(token, adminId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'delete',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/deleteAdmin/${adminId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all deleted admins
export function getAllDeletedAdmins(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/allDeletedAdmins`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// restore admin by id
export function restoreAdminById(token, adminId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/restoreAdmin/${adminId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// mark admin as rm
export function markAdminAsRm(token, adminId, makeRM) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/rolemanagement/canAdminBeRm`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }, data: JSON.stringify({
                    admin_id: adminId,
                    can_become_rm: makeRM
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}