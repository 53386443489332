import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import ToastPage from '../../includes/ToastPage';
import '../../../assets/styles/RM.css'
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import ListMandatePage from './ListMandatePage';
import EditMandatePage from './EditMandatePage';


export default function MandateMainPage() {

    const { companyList, getAllCompaniesList, showDeletedCompanies } = useCMContext()
    const { setCurrModule, allowedFunc } = useLogin()

    const history = useHistory()

    useEffect(() => {
        (async () => {
            try {
                if (allowedFunc.includes(configs.functionList['order_mandate:order_mandate'])) {
                    setCurrModule(configs.modules.mandate)
                    getAllCompaniesList()
                } else {
                    return history.goBack()
                }
            } catch (error) {

            }
        })()
    }, [])

    // role management main ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='rmContainer' className="rm-container">
                    <ListMandatePage />
                    <EditMandatePage />
                    <ToastPage />
                </div>

            </div>
        </>
    )
}