import { Link, Modal } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { downloadCompanyDataDoc } from '../../../services/apis/companyManagement/CMApi';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useCMContext } from '../../../services/contexts/companyManagement/CMContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import CompanyHighlightMgmt from '../miscellaneous/StatusManagement/CompanyHighlightMgmt';
import { getAllMandates } from '../../../services/apis/shareManagement/BuyOrdersApi';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';


export default function ListMandatePage() {

    const { companyList, setCompanyList, getAllCompaniesList, downloadFile, setEditCompany, setEditCompanyData, editCompanyData, getCompanyDataById, setShowDeletedCompanies, showDeletedCompanies, addCompanyStock, getCompanyStock } = useCMContext()
    const { tableMandateData, getAllMandate, setMandateData } = useBuyOrderContext()
    const { allowedFunc } = useLogin()
    const { setKycRequesterUserDetail } = useKYCContext();

    const [error, setError] = useState("");


    const columns = [{ field: "id", headerName: "Mandate ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    {
        field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12", valueGetter: (params) => {
            return params.row.company.name
        }
    },
    {
        field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
            return params.row.user.name
        }
    },
    {
        field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
            return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
        }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
    },
    {
        field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row.user)
                }}>{params.row.user.email}</Link>
            } else {
                return params.row.user.email
            }
        }, valueGetter: (value) => value.row.user.email
    },
    { field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.5 },
    { field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.5 },
    { field: "advance_percentage", headerName: "Advance Percentage", minWidth: 100, flex: 0.5 },
    { field: "status", headerName: "Status", minWidth: 100, flex: 0.5 },
    { field: "comment", headerName: "Comment", minWidth: 100, flex: 0.5 },
    { field: "remark", headerName: "Remark", minWidth: 100, flex: 0.5 },
    { field: "date", headerName: "Date", minWidth: 100, flex: 0.5 },
    { field: "time", headerName: "Time", minWidth: 100, flex: 0.5 },
    {
        field: "edit", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
            if (cellValues.row.status === "GENERATED") {
                return <a href="#" className="custom-link text-primary" onClick={(e) => {
                    e.preventDefault()
                    setMandateData(cellValues.row);
                    document.getElementById("rmCreateCompany").classList.toggle('d-none')
                    document.getElementById("companyListPage").classList.toggle('d-none')
                }}>Edit</a>
            }
        }
    }
    ]

    useEffect(() => {
        (async () => {
            getAllMandate();
        })()
    }, [])


    // list mandate
    return (
        <div id="companyListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-3 mb-1 mr-2">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">Mandate Management</p>
                </div>

            </div>
            <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                <a href='#' className='custom-link' onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateCompany").classList.toggle('d-none')
                    document.getElementById("companyListPage").classList.toggle('d-none')
                }}><p className='m-0 p-0'>Create New Mandate</p></a>
            </div>
            {tableMandateData ?
                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={tableMandateData}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
        </div>
    )
}