import React, { useEffect, useState } from 'react';
import { configs, downloadCSVData, formatCurrency, formatNumber } from '../../../assets/Config';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import '../../../assets/styles/BuyOrder.css'
import { downloadCSVDoc, uploadCSVDoc } from '../../../services/apis/shareManagement/InventoryAPI';
import TopBarPage from '../../includes/dashboard/TopBarPage';
import LeftBarPage from '../../includes/dashboard/LeftBarPage';
import ToastPage from '../../includes/ToastPage';
import { useBuyOrderContext } from '../../../services/contexts/BuyOrdersContext';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@material-ui/data-grid';
import BuyOrderModalPage from './BuyOrderModalPage';
import { Link } from 'react-router-dom';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import ConfirmModalPage from '../../includes/ConfirmModalPage';
import { getAllOrders, inventoryTracker } from '../../../services/apis/shareManagement/BuyOrdersApi';
import { useHistory } from 'react-router-dom';
import ErrorModalPage from '../../includes/ErrorModalPage';

export default function BuyOrderPage() {

    const { setKycRequesterUserDetail } = useKYCContext()
    const { setCurrModule, allowedFunc, showDelete, setShowDelete, showConfirm, setShowConfirm, getAllRemarkComments, setRemarkComments, logout, setLoginError, token, setShowSuccess } = useLogin()
    const { setOrderId, getOrdersByType, setUtrDetailModal, activeStatus, setActiveStatus, setDematTransferModal, setpayPendStatusModal, manuPayAckModal, setManualPayAckModal, getOrderDetailById, setOrderDetail, setHPApproveModal, setHPRejectModal, setHPRefundModal, setHPApproveQuantity, hpApprovePrice, setHPApprovePrice, hpApproveInstrument, setHPApproveInstrument, setUpdate, tableData, setTableData, downloadInvoiceByOrderId, downloadSPDSheetByOrderId, commentModal, setCommentModal, setUtrBreakupModal, setHPCompleteRefundModal, downloadHDFCSheetByOrderId } = useBuyOrderContext()
    const { showError, setShowError, changeBeneficiaryStatus } = useKYCContext()

    const [selectionModel, setSelectionModel] = useState([]);

    var [count, setCount] = useState(0)
    const [confirmId, setConfirmId] = useState({})
    const [deleteId, setDeleteId] = useState({})
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        (async () => {
            setRemarkComments([])
            setSelectionModel([])
            if (count != 0) {
                await getOrderList()
            }
        })()
    }, [activeStatus])

    useEffect(() => {
        if (allowedFunc.includes(configs.functionList['buy_module:load_all_buy_orders'])) {
            setCurrModule(configs.modules.smBuyOrder)
        } else {
            return history.goBack()
        }
    }, [])

    // get order detail 
    async function getOrderDetail(orderId) {
        try {
            let response = await getOrderDetailById(orderId)
            if (response.success) {
                setOrderDetail(response.data)
            } else {
                setOrderDetail("")
            }
        } catch (error) {

        }
    }

    // download inventory tracking sheet
    async function inventoryTrackerDownload() {
        try {
            let response = await inventoryTracker(token);
            if (response && response.status === 200) {
                let a = document.createElement("a"); //Create <a>
                a.href = response.data; //Image Base64 Goes here
                a.download = `Inventory Tracker.csv`; //File name Here
                a.click();
                return "success"
            } else if (response && response.status === 401) {
                logout();
                //setLoginError(configs.unauthorizedErrorMessage);
            }
            else {
                setError(response.data.error)
                setShowError(true)
            }
        } catch (error) {
            console.error(error);

        }
    }
    // function getOrders
    async function getOrderList() {
        try {
            let response = await getOrdersByType(configs.shareStatusTypes[activeStatus].api)
            if (response.success && response.success === "success") {
                setTableData(response.data)
            } else {

            }
        } catch (error) {

        }
    }

    // export all orders CSV
    async function exportAllOrder() {
        try {
            let response = await getAllOrders(token);
            if (response && response.status === 200) {
                downloadCSVData(response.data, "All Orders");
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            }
        } catch (error) {

        }
    }


    // columns for various data tables
    const tableColumns = {}
    tableColumns[configs.shareStatusTypes['All Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        }

    ]
    tableColumns[configs.shareStatusTypes['Completed Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        { field: "order_payment_time", headerName: "Payment Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "payment_mode", headerName: "Payment Mode", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "download", headerName: "Invoice", minWidth: 100, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                return <a href="#" className="custom-link text-primary" onClick={(e) => {
                    e.preventDefault()
                    downloadInvoiceByOrderId(cellValues.row.id)
                }}>Download</a>
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Refund Completed Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        { field: "order_payment_time", headerName: "Payment Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "payment_mode", headerName: "Payment Mode", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "download", headerName: "Invoice", minWidth: 100, flex: 0.2, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                return <a href="#" className="custom-link text-primary" onClick={(e) => {
                    e.preventDefault()
                    downloadInvoiceByOrderId(cellValues.row.id)
                }}>Download</a>
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Rejected Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "delete", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:delete_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setDeleteId({
                            "category": configs.modules.smBuyOrder,
                            "type": "Order",
                            "name": cellValues.row.id,
                            "id": cellValues.row.id
                        })
                        setShowDelete(true)
                    }}>Delete</a>
                } else {
                    return "Delete"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Deleted Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "restore", headerName: " ", minWidth: 70, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:restore_buy_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setShowConfirm(true)
                        setConfirmId({
                            "category": configs.modules.smBuyOrder,
                            "type": "Order",
                            "name": cellValues.row.id,
                            "id": cellValues.row.id
                        })
                    }}>Restore</a>
                } else {
                    return "Restore"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Cancelled Orders'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        }

    ]
    tableColumns[configs.shareStatusTypes['Payment Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:reject_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['buy_module:payment_pending'])
                        setOrderId(cellValues.row.id)
                        setHPApproveInstrument(cellValues.row.company_name)
                        setHPRejectModal(true)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        // {
        //     field: "reject", headerName: " ", minWidth: 50, flex: 0.3, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
        //         return <a href="#" className="custom-link text-danger" onClick={(e) => {
        //             e.preventDefault()
        //             setOrderId(cellValues.row.id)
        //             setpayPendStatusModal(true)
        //         }}>Reject</a>
        //     }
        // },
        {
            field: "edit", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:update_buy_order'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setUpdate(true)
                        setHPApproveQuantity(cellValues.row.quantity)
                        setHPApprovePrice(cellValues.row.price_per_share)
                        setHPApproveInstrument(cellValues.row.company_name)
                        setHPApproveModal(true)
                    }}>Edit</a>
                } else {
                    return "Edit"
                }
            }
        },
        {
            field: "payment", headerName: " ", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:manual_payment_processing_of_buy_order'])) {
                    return <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setUtrDetailModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Payment</a>
                } else {
                    return "Payment"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Acknowledgement Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "pan_number", headerName: "Pan Number", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.pan_number
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:reject_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setHPRejectModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "review", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:acknowledge_manual_payment_of_buy_order'])) {
                    return <a href="#" className="custom-link" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        getOrderDetail(cellValues.row.id)
                        setManualPayAckModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Review</a>
                } else {
                    return "Review"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Share Transfer Pending'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "current_price_per_share", headerName: "Portal Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.current_price_per_share))
            }, valueGetter: (value) => value.row.current_price_per_share
        },
        {
            field: "price_change_perc", headerName: "Price Change %", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price_change_perc))
            }, valueGetter: (value) => value.row.price_change_perc
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        {
            field: "payment_mode", headerName: "Payment Mode", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12", renderCell: (params) => {
                return <p>{params.row.payment_mode} {params.row.payment_mode === "manual" ? <i className="fa fa-info-circle curPoint text-primary" onClick={(e) => {
                    e.preventDefault();
                    setOrderId(params.row.id)
                    getOrderDetail(params.row.id)
                    setUtrBreakupModal(true)
                    setHPApproveInstrument(params.row.company_name)
                }}></i> : null}</p>
            }, valueGetter: (value) => value.row.payment_mode
        },
        { field: "order_payment_time", headerName: "Payment Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "is_beneficiary_added", headerName: "Beneficiary Added", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <input type="checkbox" id="ben_add" name="ben_add" checked={params.row.user.is_beneficiary_added} onChange={async (e) => {
                    e.preventDefault();
                    let response = await changeBeneficiaryStatus(params.row.user.id, e.target.checked);
                    if (response) {
                        getOrderList();
                        setShowSuccess(true);
                    } else {
                        setShowError(true);
                    }
                }} />
            }, valueGetter: (params) => params.row.user.is_beneficiary_added ? "Yes" : "No"
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "account_no", headerName: "Account No.", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_account_number
            }
        },
        {
            field: "bank_name", headerName: "Bank Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_name
            }
        },
        {
            field: "ifsc", headerName: "IFSC", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_ifsc_code
            }
        },
        {
            field: "pan_number", headerName: "Pan Number", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.pan_number
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        {
            field: "payment_ref_number", headerName: "Payment Ref. No.", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.payment_details.payment_ref_number
            }
        },
        {
            field: "utr_bank_name", headerName: "Manual Payment Bank", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.payment_details.utr_bank_name
            }
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "reject", headerName: " ", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:reject_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setHPRejectModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "refund", headerName: " ", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:initiate_refund'])) {
                    return <a href="#" className="custom-link text-primary" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setHPRefundModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Refund</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "completeDelivery", headerName: " ", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:transfer_shares_of_buy_order'])) {
                    return <a href="#" className="custom-link text-success" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['buy_module:share_delivered'])
                        setOrderId(cellValues.row.id)
                        setDematTransferModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Complete Delivery</a>
                } else {
                    return "Complete Delivery"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Refund Initiated'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.price
        },
        {
            field: "total_tds", headerName: "Total TDS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tds))
            }, valueGetter: (value) => value.row.total_tds
        },
        {
            field: "total_tcs", headerName: "Total TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.total_tcs))
            }, valueGetter: (value) => value.row.total_tcs
        },
        {
            field: "referral_bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.referral_bonus
        },
        {
            field: "coupon_code", headerName: "Coupon Code", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12"
        },
        {
            field: "coupon_discount", headerName: "Coupon Discount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.coupon_discount))
            }, valueGetter: (value) => value.row.coupon_discount
        },
        {
            field: "amount_to_be_paid", headerName: "Payable Amount", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid))
            }, valueGetter: (value) => value.row.amount_to_be_paid
        },
        {
            field: "amount_to_be_paid_with_tds_tcs", headerName: "Amount With TDS/TCS", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.amount_to_be_paid_with_tds_tcs))
            }, valueGetter: (value) => value.row.amount_to_be_paid_with_tds_tcs
        },
        {
            field: "upmark", headerName: "Upmark", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark))
            }, valueGetter: (value) => value.row.upmark
        },
        {
            field: "upmark_final_pay", headerName: "Upmark Final Pay", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.upmark_final_pay))
            }, valueGetter: (value) => value.row.upmark_final_pay
        },
        {
            field: "payment_mode", headerName: "Payment Mode", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12", renderCell: (params) => {
                return <p>{params.row.payment_mode} {params.row.payment_mode === "manual" ? <i className="fa fa-info-circle curPoint text-primary" onClick={(e) => {
                    e.preventDefault();
                    setOrderId(params.row.id)
                    getOrderDetail(params.row.id)
                    setUtrBreakupModal(true)
                    setHPApproveInstrument(params.row.company_name)
                }}></i> : null}</p>
            }, valueGetter: (value) => value.row.payment_mode
        },
        { field: "order_payment_time", headerName: "Payment Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "is_beneficiary_added", headerName: "Beneficiary Added", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <input type="checkbox" id="ben_add" name="ben_add" checked={params.row.user.is_beneficiary_added} onChange={async (e) => {
                    e.preventDefault();
                    let response = await changeBeneficiaryStatus(params.row.user.id, e.target.checked);
                    if (response) {
                        getOrderList();
                        setShowSuccess(true);
                    } else {
                        setShowError(true);
                    }
                }} />
            }, valueGetter: (params) => params.row.user.is_beneficiary_added ? "Yes" : "No"
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "account_no", headerName: "Account No.", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_account_number
            }
        },
        {
            field: "bank_name", headerName: "Bank Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_name
            }
        },
        {
            field: "ifsc", headerName: "IFSC", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.bank_ifsc_code
            }
        },
        {
            field: "pan_number", headerName: "Pan Number", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.pan_number
            }
        },
        {
            field: "dp_id", headerName: "DP ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.dp_id
            }
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        {
            field: "payment_ref_number", headerName: "Payment Ref. No.", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.payment_details.payment_ref_number
            }
        },
        {
            field: "utr_bank_name", headerName: "Manual Payment Bank", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.payment_details.utr_bank_name
            }
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "completeDelivery", headerName: " ", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:complete_refund'])) {
                    return <a href="#" className="custom-link text-success" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['buy_module:share_delivered'])
                        setOrderId(cellValues.row.id)
                        setHPCompleteRefundModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Complete Refund</a>
                } else {
                    return "Complete Refund"
                }
            }
        }
    ]
    tableColumns[configs.shareStatusTypes['Intervention Needed'].name] = [
        { field: "id", headerName: "Order ID", minWidth: 50, flex: 0.4 },
        { field: "company_name", headerName: "Company Name", minWidth: 200, flex: 0.5, cellClassName: "break-text f-12" },
        {
            field: "name", headerName: "Name", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.name
            }
        },
        {
            field: "mobile", headerName: "Phone No.", minWidth: 100, flex: 0.3, cellClassName: "break-text f-12", renderCell: (params) => {
                return <a href={"https://wa.me/" + params.row.user.mobile} target="_blank">{params.row.user.mobile ? `+${params.row.user.mobile}` : params.row.user.mobile}</a>
            }, valueGetter: (value) => value.row.user.mobile ? `+${value.row.user.mobile}` : value.row.user.mobile
        },
        {
            field: "email", headerName: "E-mail", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                    return <Link to={`/customerProfile/${params.row.user.id}`} onClick={() => {
                        setKycRequesterUserDetail(params.row.user)
                    }}>{params.row.user.email}</Link>
                } else {
                    return params.row.user.email
                }
            }, valueGetter: (value) => value.row.user.email
        },
        {
            field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.name ? params.row.user && params.row.user.distributor && params.row.user.distributor.name : ""
        },
        {
            field: "utm", headerName: "UTM", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm : ""
        },
        {
            field: "utm_recorded_from", headerName: "UTM Recorded From", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
                return params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
            }, valueGetter: (params) => params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from ? params.row.user && params.row.user.distributor && params.row.user.distributor.utm_recorded_from : ""
        },
        {
            field: "rm_email", headerName: "RM Email", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.email : ""
        },
        {
            field: "rm_name", headerName: "RM Name", minWidth: 100, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
            }, valueGetter: (params) => params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name ? params.row.user && params.row.user.relationship_manager && params.row.user.relationship_manager.name : ""
        },
        {
            field: "client_id", headerName: "Client ID", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return params.row.user.client_id
            }
        },
        { field: "order_time", headerName: "Order Time", minWidth: 150, flex: 0.4, cellClassName: "break-text f-12" },
        {
            field: "quantity", headerName: "Quantity", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", valueGetter: (params) => {
                return formatNumber(parseInt(params.row.quantity))
            }
        },
        {
            field: "price_per_share", headerName: "Price/Share", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return <>{formatCurrency(parseFloat(params.row.price_per_share))}
                    {params.row.price_per_share && params.row.old_order_price && (params.row.price_per_share !== (params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity))) ?
                        <div id={`tooltip${params.row.id}`}>
                            <i className="fa fa-info-circle curPoint pl-2 text-primary" aria-hidden="true" onClick={(e) => {
                                e.preventDefault();
                                document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'block'
                            }} />
                            <div className='tooltipBox card'>
                                <div className="priceSlab">
                                    <p className='my-1'><b>Order Rate Update</b></p>
                                    <p className='text-right text-danger curPoint my-1' onClick={(e) => {
                                        e.preventDefault();
                                        document.getElementById(`tooltip${params.row.id}`).getElementsByClassName("tooltipBox")[0].style.display = 'none'
                                    }}><b>X</b></p>
                                </div>
                                <div className='mb-1'>Current Rate: {formatCurrency(parseFloat(params.row.price_per_share))}</div>
                                <div className='m-0'>Old Rate: {formatCurrency(parseFloat(params.row.old_order_price / (params.row.old_order_quantity ? params.row.old_order_quantity : params.row.quantity)))}</div>
                            </div>
                        </div> : null}
                </>
            }, valueGetter: (value) => value.row.price_per_share
        },
        {
            field: "price", headerName: "Price", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.price))
            }, valueGetter: (value) => value.row.user.price
        },
        {
            field: "Referral Bonus", headerName: "Referral Bonus", minWidth: 100, flex: 0.4, cellClassName: "break-text f-12", renderCell: (params) => {
                return formatCurrency(parseFloat(params.row.referral_bonus))
            }, valueGetter: (value) => value.row.user.price
        },
        { field: "status", headerName: "Status", minWidth: 150, flex: 0.4, cellClassName: "break-text text-capitalize f-12" },
        {
            field: "comment", headerName: "Comment", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return <div>{params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""}<i className='ml-2 fas fa-edit text-primary curPoint' onClick={(e) => {
                    setOrderId(params.row.id)
                    setCommentModal(true)
                }} /></div>
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.text ? params.row.user.comment.text : ""
        },
        {
            field: "comment_name", headerName: "Commenter Name", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.commenter && params.row.user.comment.commenter.name ? params.row.user.comment.commenter.name : ""
        },
        {
            field: "comment_date", headerName: "Comment Timestamp", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
                return params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
            }, valueGetter: (params) => params.row.user.comment && params.row.user.comment.timestamp ? params.row.user.comment.timestamp : ""
        },
        {
            field: "reject", headerName: " ", minWidth: 50, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:reject_buy_order'])) {
                    return <a href="#" className="custom-link text-danger" onClick={(e) => {
                        e.preventDefault()
                        getAllRemarkComments(configs.remarkCategories['buy_module:intervention_needed'])
                        setOrderId(cellValues.row.id)
                        setHPRejectModal(true)
                        setHPApproveInstrument(cellValues.row.company_name)
                    }}>Reject</a>
                } else {
                    return "Reject"
                }
            }
        },
        {
            field: "approve", headerName: " ", minWidth: 70, flex: 0.4, cellClassName: "break-text f-12", filterable: false, renderCell: (cellValues) => {
                if (allowedFunc.includes(configs.functionList['buy_module:approve_intervention_needed_buy_order'])) {
                    return <a href="#" className="custom-link text-success" onClick={(e) => {
                        e.preventDefault()
                        setOrderId(cellValues.row.id)
                        setHPApproveQuantity(cellValues.row.quantity)
                        setHPApprovePrice(cellValues.row.price_per_share)
                        setHPApproveInstrument(cellValues.row.company_name)
                        setHPApproveModal(true)
                    }}>Approve</a>
                } else {
                    return "Approve"
                }
            }
        }
    ]

    // download hdfc sheet
    async function exportSPD() {
        try {
            let ele = document.getElementById('buyOrderContainer').click();
            let response = await downloadSPDSheetByOrderId(selectionModel)
            if (response && response === "success") {
                setSelectionModel([])
            } else {
                setError(response)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // add hdfc export option in export toolbar
    function addSPDExportButton() {
        try {
            setTimeout(() => {
                // get root element to add element
                let ele = document.getElementsByClassName("MuiList-root MuiDataGrid-gridMenuList MuiList-padding")
                if ((ele.length > 0) && (selectionModel.length > 0) && (activeStatus === configs.shareStatusTypes['Share Transfer Pending'].name)) {
                    //create new element to add
                    var li = document.createElement('li')
                    li.className = "MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button";
                    let span = document.createElement('span');
                    span.className = "MuiTouchRipple-root"
                    li.appendChild(document.createTextNode("SPD Sheet"))
                    li.appendChild(span)
                    li.tabIndex = 0
                    li.setAttribute("role", "menu-item")
                    li.ariaDisabled = "false"
                    li.addEventListener("click", exportSPD);

                    //add new elemet to parent
                    ele[0].appendChild(li)
                }
            }, 200)
        } catch (error) {
            console.error(error)
        }
    }

    // download hdfc sheet
    async function exportHDFC() {
        try {
            let ele = document.getElementById('buyOrderContainer').click();
            let response = await downloadHDFCSheetByOrderId(selectionModel)
            if (response && response === "success") {
                setSelectionModel([])
            } else {
                setError(response)
                setShowError(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // add hdfc export option in export toolbar
    function addHDFCExportButton() {
        try {
            setTimeout(() => {
                // get root element to add element
                let ele = document.getElementsByClassName("MuiList-root MuiDataGrid-gridMenuList MuiList-padding")
                if ((ele.length > 0) && (selectionModel.length > 0) && (activeStatus === configs.shareStatusTypes["Refund Initiated"].name)) {
                    //create new element to add
                    var li = document.createElement('li')
                    li.className = "MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button";
                    let span = document.createElement('span');
                    span.className = "MuiTouchRipple-root"
                    li.appendChild(document.createTextNode("HDFC Sheet"))
                    li.appendChild(span)
                    li.tabIndex = 0
                    li.setAttribute("role", "menu-item")
                    li.ariaDisabled = "false"
                    li.addEventListener("click", exportHDFC);

                    //add new elemet to parent
                    ele[0].appendChild(li)
                }
            }, 200)
        } catch (error) {
            console.error(error)
        }
    }

    // react data table export option
    function CustomToolbar() {
        try {
            let ele = document.querySelectorAll("[aria-label^='Export']");
            if (ele.length > 0 && activeStatus === configs.shareStatusTypes['Share Transfer Pending'].name) {
                ele[0].addEventListener("click", addSPDExportButton)
            } else if (ele.length > 0) {
                ele[0].addEventListener("click", addHDFCExportButton)
            }
        } catch (error) {
            console.error(error)
        }
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // buy order ui code
    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id='buyOrderContainer' className="smbo-container">
                    <div style={{ width: "100%" }}>
                        <div className="row mx-md-4 mx-2 my-2 p-0">
                            <div className="col-md-6 m-0 p-0">
                                <div className='row m-0 mb-2 p-0'>
                                    <div className='col-4 p-0'>
                                        <p className="h5 text-custom-grey">{activeStatus}</p>
                                    </div>
                                    <div className='col-4 p-0 text-right'>
                                        <button className="btn btn-primary" onClick={(e) => {
                                            e.preventDefault()
                                            exportAllOrder();
                                        }}>Export All Orders</button>
                                    </div>
                                    <div className='col-4 p-0 text-right'>
                                        <button className="btn btn-primary" onClick={(e) => {
                                            e.preventDefault()
                                            inventoryTrackerDownload();
                                        }}>Inventory Tracker</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-md-right text-left m-0 p-0">
                                <span className="text-primary mr-2">Status</span>
                                <select name="" id="" className=" py-1 px-2 border rounded" value={activeStatus ? activeStatus : ""} onChange={(e) => {
                                    setTableData("")
                                    setActiveStatus(e.target.value)
                                }}>
                                    {Object.keys(configs.shareStatusTypes).map((element, key) => {
                                        if (allowedFunc.includes(configs.shareStatusTypes[element].functionName)) {
                                            if (count === 0) {
                                                setActiveStatus(configs.shareStatusTypes[element].name)
                                                setCount(count += 1)
                                            }
                                            return (
                                                <option key={`shareStatusKey` + key} value={configs.shareStatusTypes[element].name}>{configs.shareStatusTypes[element].name}</option>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="kyc-table">
                            {tableData ? <DataGrid
                                rows={tableData}
                                columns={tableColumns[configs.shareStatusTypes[activeStatus].name]}
                                resizable={true}
                                autoPageSize
                                pagination
                                headerHeight={30}
                                rowHeight={40}
                                checkboxSelection={activeStatus === configs.shareStatusTypes['Share Transfer Pending'].name || activeStatus === configs.shareStatusTypes['Refund Initiated'].name ? true : false}
                                onSelectionModelChange={(newSelectionModel) => {
                                    if (activeStatus === configs.shareStatusTypes['Share Transfer Pending'].name || activeStatus === configs.shareStatusTypes['Refund Initiated'].name) {
                                        setSelectionModel(newSelectionModel);
                                        let ele = document.getElementById('buyOrderContainer').click();
                                    }
                                }}
                                selectionModel={activeStatus === configs.shareStatusTypes['Share Transfer Pending'].name || activeStatus === configs.shareStatusTypes['Refund Initiated'].name ? selectionModel : []}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                                : <div className="loader"></div>}
                        </div>
                    </div>
                </div>
                <BuyOrderModalPage />
                <ToastPage />
                <ErrorModalPage error={error} />
                {showDelete ? <DeleteModalPage {...deleteId} /> : showConfirm ? <ConfirmModalPage {...confirmId} /> : null}
            </div>
        </>
    )
}