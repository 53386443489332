import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { configs } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import { useRMContext } from '../../../services/contexts/RMContext';
import ConfirmModalPage from '../../includes/ConfirmModalPage';
import DeleteModalPage from '../../includes/DeleteModalPage';
import { useKYCContext } from '../../../services/contexts/KYCContext';

export default function AMListPage() {

    const { getAllAdmin, adminList, setAdminList, setUpdateAdmin, setCreateAdminRoleId, editAdminDetail, setEditAdminDetail, deleteAdmin, showDeletedAdmins, setShowDeletedAdmins, deletedAdmins, setDeletedAdmins, getDeletedAdminList, markAdminasRM } = useRMContext();
    const { showDelete, setShowDelete, showConfirm, setShowConfirm, setShowSuccess } = useLogin()
    const { setShowError } = useKYCContext()

    const [confirmId, setConfirmId] = useState({})
    const [deleteId, setDeleteId] = useState({})

    useEffect(() => {
        (async () => {
            try {
                if (!showDeletedAdmins) {
                    await getAllAdmin()
                } else {
                    await getDeletedAdminList()
                }
            } catch (error) {

            }
        })();
    }, [showDeletedAdmins])


    const columns = [{ field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "email", headerName: "Email", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "mobile", headerName: "Mobile No.", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "account_created_on", headerName: "Account Created On", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "role_name", headerName: "Assigned Role", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "isRM", headerName: "RM", minWidth: 50, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return <input type="checkbox" id="ben_add" name="ben_add" checked={params.row.can_become_rm} onChange={async (e) => {
                e.preventDefault();
                let response = await markAdminasRM(params.row.id, e.target.checked);
                if (response) {
                    getAllAdmin();
                    setShowSuccess(true);
                } else {
                    setShowError(true);
                }
            }} />
        }, valueGetter: (params) => params.row.can_become_rm ? "Yes" : "No"
    },
    {
        field: "edit", headerName: " ", minWidth: 50, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setCreateAdminRoleId(params.row.role_id)
                setUpdateAdmin(true)
                setEditAdminDetail({
                    "edit": true,
                    "data": params.row
                })
            }}>Edit</a>
        }
    },
    {
        field: "delete", headerName: " ", minWidth: 50, flex: 0.1, cellClassName: "break-text", cellClassName: "text-danger", renderCell: (params) => {
            return <a href="#" className="custom-link text-danger" onClick={(e) => {
                e.preventDefault()
                setDeleteId({
                    "category": configs.modules.am,
                    "type": "Admin",
                    "name": params.row.name,
                    "id": params.row.id
                })
                setShowDelete(true)
            }}>Delete</a>
        }
    }
    ]

    const delColumns = [{ field: "id", headerName: "User ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    { field: "name", headerName: "Name", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "email", headerName: "Email", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "mobile", headerName: "Mobile No.", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    { field: "account_created_on", headerName: "Account Created On", minWidth: 100, flex: 0.4, cellClassName: "break-text" },
    { field: "role_name", headerName: "Assigned Role", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "restore", headerName: " ", minWidth: 50, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                setShowConfirm(true)
                setConfirmId({
                    "category": configs.modules.am,
                    "type": "Admin",
                    "name": params.row.name,
                    "id": params.row.id
                })
            }}>Restore</a>
        }
    }
    ]

    // role list page ui code
    return (
        <div style={{ width: "100%" }}>
            <div className="row align-items-center mt-2 ml-2 mb-4 mr-3">
                <div className="col-md-4 col-sm-6 text-left">
                    <p className="h4 text-custom-grey">{!showDeletedAdmins ? "Manage Admins" : "Deleted Admins"}</p>
                </div>
                <div className="col-md-8 col-sm-6 text-md-right text-left ">
                    <a href="#" className="custom-link mr-4" onClick={(e) => {
                        e.preventDefault()
                        setAdminList("")
                        setDeletedAdmins("")
                        setShowDeletedAdmins(!showDeletedAdmins)
                    }}><i className={showDeletedAdmins ? "fas fa-user mr-2" : "fas fa-user-slash mr-2"}></i>{showDeletedAdmins ? "Active Admins" : "Deleted Admins"}</a>
                    {!showDeletedAdmins ? <a href="" className="mr-4 custom-link" onClick={(e) => {
                        e.preventDefault()
                        setUpdateAdmin(true)
                    }}><i className="fas fa-plus mr-2"></i>Add New Admin</a> : null}
                    {!showDeletedAdmins ? <Link to="/roleManagement" className="custom-link"><i className="fas fa-tasks mr-2"></i>Manage Roles</Link> : null}
                </div>
            </div>
            <div className="am-role-table">
                {!showDeletedAdmins && adminList ? <DataGrid
                    rows={adminList}
                    headerHeight={40}
                    rowHeight={30}
                    columns={columns}
                    resizable={true}
                    autoPageSize
                    pagination
                    components={{
                        Toolbar: GridToolbar,
                    }}
                // onSelectionModelChange={onRowSelected}
                />
                    : showDeletedAdmins && deletedAdmins ? <DataGrid
                        rows={deletedAdmins}
                        headerHeight={40}
                        rowHeight={30}
                        columns={delColumns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    /> : <div className="loader"></div>}
            </div>
            {showDelete ? <DeleteModalPage {...deleteId} /> : showConfirm ? <ConfirmModalPage {...confirmId} /> : null}
        </div>
    )
}