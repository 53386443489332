import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../../../assets/styles/TopBar.css'
import imgn from '../../../assets/images/default-user-profile.svg';
import logo from '../../../assets/images/logo.png';
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import ProfileModalPage from '../ProfileModalPage';
import { showLeftBar } from '../../../assets/Config';
import { useNMContext } from '../../../services/contexts/NMContext';

export default function TopBarPage() {

    const { setProfileModal, token } = useLogin();
    const { whatsappContacts } = useNMContext();
    const history = useHistory();

    useEffect(async () => {
        try {
            if (Notification.permission !== "granted")
                Notification.requestPermission();

            // const evtSource = new EventSource(`${process.env.REACT_APP_BASEURL}/admin/whatsapp/message-sse`, { withCredentials: true });
            // evtSource.onmessage = (event) => {
            //     let data = event.data ? JSON.parse(event.data) : "";
            //     if (data && data.data && data.data.length > 0) {
            //         whatsappContacts();
            //         const notification = new Notification("Altius Investech", { body: "You have new messages on WhatsApp!", icon: logo });
            //         notification.onclick = (event) => {
            //             window.parent.parent.focus();
            //             history.push('/whatsappMessages')
            //         };
            //     }
            // };

            // const response = await fetch(`${process.env.REACT_APP_BASEURL}/admin/whatsapp/message-sse`, { headers: { 'Authorization': `Bearer ${token}`, "Accept": "text/event-stream" } });
            // const reader = response.body.getReader();
            // const decoder = new TextDecoder();
            // async function readStream() {
            //     const { done, value } = await reader.read();
            //     console.log(done);

            //     // DONE
            //     if (done) return response;

            //     const chunk = decoder.decode(value); // 解码 Uint8Array
            //     console.log(chunk);

            //     await readStream();
            // }
            // await readStream();

        } catch (error) {
            console.error(error);
        }
    }, [])

    return (<div id="topbar" className="row p-0 m-0">
        <div className="col-lg-12 p-0 m-0 position-relative">
            <div className="row py-2 px-2 px-md-4 m-0 navbar-container align-items-center ">
                <div className='d-lg-none col-md-1 col-2'>
                    <i className="fa fa-bars" aria-hidden="true" onClick={(e) => {
                        e.preventDefault()
                        showLeftBar(true)
                    }}></i>
                </div>
                <div className='col-10 col-md-11 col-lg-12 m-0 p-0 d-flex justify-content-between align-items-center'>
                    <div className="navbar-company-logo-container">
                        <img className="w-100 h-100" src={logo} alt="company logo" />
                    </div>
                    <div className="navbar-items-container d-md-flex d-none justify-content-end align-items-center flex-row-reverse">
                        <div className="navbar-items-user" onClick={(e) => {
                            setProfileModal(true);
                        }} >
                            <img className="w-100 h-100" src={imgn} alt="user" />
                        </div>
                        {/* <div className="navbar-items-link">
                        <a href="#">Order Book</a>
                    </div>
                    <div className="navbar-items-link">
                        <a href="#">Holding</a>
                    </div>
                    <div className="navbar-items-link">
                        <a href="#">Companies</a>
                    </div> */}
                    </div>
                    <div className="navbar-mobile-items-container d-md-none d-flex justify-content-end align-items-center flex-row-reverse">
                        <div className="navbar-items-user" onClick={(e) => {
                            setProfileModal(true);
                        }}>
                            <img className="w-100 h-100" src={imgn} alt="user" />
                        </div>
                        {/* <div className="navbar-mobile-items-link">
                        <a href="#">
                            <i className="fas fa-plus-square"></i>
                        </a>
                    </div>
                    <div className="navbar-mobile-items-link">
                        <a onClick={showLeftBar}>
                            <i className="fas fa-eye"></i>
                        </a>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
        <ProfileModalPage />
    </div>)
}